import { useContext, useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../core/cartProvider";
import { Course } from "../models/course";
import { AuthService } from "../auth/sso/auth.service";
import { axiosAuthInstance, axiosAuthPublicInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig } from "../config/Configuration";
import Coupons from "./Admin/Coupons/Coupons";
import { BeginCheckoutRequest } from "./Payment";
import Swal from "sweetalert2";
import DefaultImg from '../assets/images/corso-teaser-demo.png';
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { CiPlay1 } from "react-icons/ci";
import { MdOutlineSubscriptions } from "react-icons/md";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { GoLock } from "react-icons/go";
import { PiHeadset } from "react-icons/pi";

const Cart = () => {
    const navigate = useNavigate();
    const { cartItems, getCartTotal, removeFromCart, getPromoCode, applyPromoCode } = useContext(CartContext);
    const [promoCode, setPromoCode] = useState<string>('');

    const [isValidCoupon, setIsValidCoupon] = useState<boolean>(false);
    const [couponMessage, setCouponMessage] = useState<string>('');

    const [cartTotalInfo, setCartTotalInfo] = useState<any>({});


    const [clientSecret, setClientSecret] = useState<string | null>(null); // Client secret generato dal backend
    const [intentID, setIntentID] = useState<string | null>(null); // ID dell'intent generato dal backend
    const [checkoutBody, setCheckoutBody] = useState<BeginCheckoutRequest | null>(null); // Body della richiesta di checkout

    const [isProcessingCheckout, setIsProcessingCheckout] = useState<boolean>(false);


    useEffect(() => {
        if (!cartItems || cartItems.length === 0) {
            setCartTotalInfo({ SubTotal: 0, Total: 0, Coupons: [], SpecialDiscount: 0, VAT: 0 });
            return;
        }

        const handleCartTotalInfo = async () => {
            setCartTotalInfo(await getCartTotal());
        }

        handleCartTotalInfo();
    }, [cartItems, getPromoCode]);

    const handlePromoCode = async () => {
        // Controllo la validità del codice promozionale
        try {
            const response = await axiosAuthPublicInstance.post(`${ApiConfig.ROOT_URL}/orders/validate-coupon`, { Coupon: promoCode });
            if (response.data.IsValid) {
                setCouponMessage(TextDictionary.Carrello_Checkout.MessaggioCouponValido);
                setIsValidCoupon(true);
                applyPromoCode(promoCode);
                setPromoCode('');
                // // Aggiorno il totale del carrello
                // setCartTotalInfo(await getCartTotal());
            } else {
                setCouponMessage(TextDictionary.Carrello_Checkout.MessaggioCouponNonValido);
                setIsValidCoupon(false);
                setPromoCode('');
                applyPromoCode('');

                throw new Error("Coupon non valido");
            }
        } catch (error: any) {
            setCouponMessage(error.message);
            setIsValidCoupon(false);
            setPromoCode('');
            applyPromoCode('');
        }
    }

    const ProductCard = (product: any) => {
        return (
            <div className="flex flex-row justify-between items-center p-4 border-b border-black border-opacity-20">
                <div className="flex flex-row gap-4 items-center">
                    <img src={product.ImageURL ? product.ImageURL : DefaultImg} alt="Product" className="w-16 h-16 object-contain cursor-pointer"
                        onClick={() => {
                            if (product.Type === 'Course')
                                navigate(`/public/corsi/${product.ID}`)
                            else if (product.Type === 'Live')
                                navigate(`/public/lives/${product.ID}`)
                        }}
                    />
                    <div>
                        <div className="flex flex-row gap-8 items-center">
                            <p className="text-lg font-medium cursor-pointer hover:underline flex flex-row gap-4 items-center"
                                onClick={() => {
                                    if (product.Type === 'Course')
                                        navigate(`/public/corsi/${product.ID}`)
                                    else if (product.Type === 'Live')
                                        navigate(`/public/lives/${product.ID}`)
                                }}
                            >
                                {product.Title}
                            </p>
                            {product.Type === 'Subscription' && <div className="bg-primary text-white text-xs font-medium py-1 px-2">{TextDictionary.Carrello_Checkout.LabelAbbonamento}</div>}
                        </div>

                        {product.Type === 'Live' && (
                            <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296.89 184.4" height={30} width={30}>
                                <g id="Layer_1" data-name="Layer 1" fill="#ed1c24">
                                    <g>
                                        <g>
                                            <path d="M109.48,174.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-29.27c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h8.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v32.43h19.28Z" />
                                            <path d="M118.33,184.4c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h8.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v40.84c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-8.28Z" />
                                            <path d="M150.57,184.4c-1.64,0-2.76-.84-3.35-2.53l-12.9-39.51c-.08-.29-.14-.48-.16-.57-.02-.08-.03-.17-.03-.25,0-.38.14-.71.41-.98.27-.27.6-.41.98-.41h7.97c.84,0,1.44.18,1.8.54.36.36.58.71.66,1.04l8.79,27.82,8.79-27.82c.08-.34.31-.68.66-1.04.36-.36.96-.54,1.8-.54h7.97c.38,0,.71.14.98.41.27.27.41.6.41.98,0,.08-.01.17-.03.25-.02.08-.07.27-.16.57l-12.9,39.51c-.59,1.69-1.71,2.53-3.35,2.53h-8.34Z" />
                                            <path d="M212.84,174.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-29.97c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h29.46c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-19.47v6.83h18.08c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-18.08v7.08h19.98Z" />
                                        </g>
                                        <path d="M148.95,48.5c-15.53,0-28.12,12.59-28.12,28.12s12.59,28.12,28.12,28.12,28.12-12.59,28.12-28.12-12.59-28.12-28.12-28.12ZM166.7,79.02l-13.43,7.75-13.43,7.75c-1.85,1.07-4.16-.27-4.16-2.4v-31.01c0-2.13,2.31-3.47,4.16-2.4l13.43,7.75,13.43,7.75c1.85,1.07,1.85,3.73,0,4.8Z" />
                                        <g>
                                            <path d="M233.04,133.36c-1.97,0-3.96-.54-5.74-1.68-4.97-3.17-6.43-9.78-3.26-14.76,7.67-12.02,11.73-25.96,11.73-40.29s-4.06-28.28-11.73-40.3c-3.18-4.97-1.72-11.58,3.26-14.76,4.97-3.18,11.58-1.72,14.76,3.26,9.87,15.47,15.09,33.38,15.09,51.8s-5.22,36.33-15.08,51.79c-2.04,3.19-5.49,4.94-9.02,4.94Z" />
                                            <path d="M267.49,153.25c-1.92,0-3.86-.52-5.62-1.6-5.02-3.11-6.57-9.69-3.46-14.71,11.19-18.08,17.1-38.93,17.1-60.31s-5.91-42.23-17.1-60.31c-3.11-5.02-1.56-11.61,3.46-14.71,5.02-3.11,11.61-1.56,14.71,3.46,13.28,21.46,20.3,46.2,20.3,71.56s-7.02,50.1-20.3,71.56c-2.02,3.27-5.52,5.06-9.1,5.06Z" />
                                            <path d="M198.85,113.01c-2.06,0-4.14-.59-5.97-1.83-4.89-3.3-6.18-9.95-2.87-14.84,3.94-5.83,6.02-12.64,6.02-19.71s-2.16-14.15-6.25-20.05c-3.36-4.85-2.15-11.51,2.7-14.87,4.85-3.36,11.51-2.15,14.87,2.7,6.57,9.49,10.05,20.63,10.05,32.22s-3.35,22.3-9.68,31.68c-2.07,3.06-5.44,4.71-8.87,4.71Z" />
                                        </g>
                                        <g>
                                            <path d="M63.85,133.36c-3.53,0-6.98-1.75-9.02-4.94-9.87-15.46-15.09-33.37-15.09-51.79s5.22-36.34,15.09-51.8c3.18-4.98,9.78-6.43,14.76-3.26,4.98,3.18,6.44,9.78,3.26,14.76-7.67,12.02-11.73,25.96-11.73,40.3s4.06,28.27,11.73,40.29c3.18,4.98,1.71,11.58-3.26,14.76-1.78,1.14-3.77,1.68-5.74,1.68Z" />
                                            <path d="M29.4,153.25c-3.58,0-7.08-1.8-9.1-5.06C7.02,126.72,0,101.98,0,76.62S7.02,26.52,20.3,5.06C23.41.04,30-1.51,35.02,1.6c5.02,3.11,6.57,9.69,3.46,14.71-11.19,18.08-17.1,38.93-17.1,60.31s5.91,42.23,17.1,60.31c3.11,5.02,1.56,11.61-3.46,14.71-1.75,1.08-3.7,1.6-5.62,1.6Z" />
                                            <path d="M98.04,113.01c-3.43,0-6.8-1.65-8.87-4.71-6.33-9.37-9.68-20.33-9.68-31.68s3.48-22.72,10.05-32.22c3.36-4.85,10.02-6.06,14.87-2.7,4.85,3.36,6.06,10.02,2.7,14.87-4.09,5.9-6.25,12.83-6.25,20.05s2.08,13.89,6.02,19.71c3.3,4.89,2.02,11.53-2.87,14.84-1.83,1.24-3.91,1.83-5.97,1.83Z" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        )}

                        <div className="flex flex-row gap-2 items-center">
                            {/* mostro prezzo vecchio (barrato) e prezzo scontato */}
                            {product.DiscountPrice ? (
                                <>
                                    <p className="text-sm lg:text-md text-gray-500 line-through">{product.RegularPrice?.toFixed(2) ?? 0.00} €</p>
                                    <p className="text-sm lg:text-md text-primary">{product.DiscountPrice?.toFixed(2) ?? 0.00} €</p>
                                </>
                            ) : (
                                <p className="text-sm lg:text-md text-primary">{product.RegularPrice?.toFixed(2) ?? 0.00} €</p>
                            )}
                        </div>

                        {/* Tasto per rimuovere il prodotto dal carrello */}
                        <button className="text-sm font-light underline"
                            onClick={() => removeFromCart(product)}
                        >{TextDictionary.Carrello_Checkout.BottoneRimuoviProdotto}</button>
                    </div>
                </div>
                {/* <p className="text-lg font-medium text-primary">{product.DiscountPrice ? product.DiscountPrice.toFixed(2) : 0.00} €</p> */}
                {product.DiscountPrice ? <p className="text-sm lg:text-md font-medium text-primary">{product.DiscountPrice.toFixed(2)} €</p> : <p className="text-lg font-medium text-primary">{product.RegularPrice.toFixed(2)} €</p>}

            </div>
        );
    }

    const handleBeginCheckout = async () => {
        setIsProcessingCheckout(true);
        // 1. Costruisco il payload per la begin checkout
        const body: BeginCheckoutRequest = {
            Note: "Nota dell'ordine",
        };

        // costruisco il body della richiesta di checkout basandomi sul carrello (cartItems)
        body.OrderRows = cartItems.map((product: any) => {
            if (product.Type === 'Course') {
                return {
                    CourseID: product.ID,
                    Note: "Nota corso"
                };
            } else if (product.Type === 'Live') {
                return {
                    LiveID: product.ID,
                    Note: "Nota abbonamento"
                };
            } else {
                return {
                    SubscriptionID: product.ID,
                    Note: "Nota abbonamento"
                };
            }
        });

        if (getPromoCode() !== '') // Se c'è un codice promozionale valido collegato al carrello
            body.Coupons = [getPromoCode()]; // Aggiungo il codice promozionale
        else body.Coupons = [];

        // 2. Effetto la chiamata a begin-checkout con il body costruito
        try {
            const response = await axiosAuthInstance.post(ApiConfig.ROOT_URL + "/orders/begin-checkout", body);

            // OrderID, IntentID, IntentClientSecret

            const { IntentClientSecret, IntentID, StripeProductID, StripePriceID, StripeSubscriptionID } = response.data;

            setClientSecret(IntentClientSecret);
            setIntentID(IntentID);
            setCheckoutBody(body);

            setIsProcessingCheckout(false);


            // 3. Redirect a /payment con i parametri
            navigate('/payment', {
                state: {
                    clientSecret: IntentClientSecret,
                    intentID: IntentID,
                    checkoutBody: body,
                    stripeProductID: StripeProductID,
                    stripePriceID: StripePriceID,
                    stripeSubscriptionID: StripeSubscriptionID,
                }
            });

        } catch (error: any) {
            console.error("Errore durante l'inizio del checkout", error);

            Swal.fire({
                title: 'Attenzione!',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK'
            });

            setIsProcessingCheckout(false);
        }
    };

    const AdditionalInfo = ({ title, content, icon }: { title: string, content: string, icon: any }) => {
        return (
            <>
                <div className="flex flex-row gap-4 items-center">
                    {icon}
                    <p>{title}</p>
                </div>
                <p className="text-sm font-light" dangerouslySetInnerHTML={content ? { __html: content } : { __html: '' }}></p>
            </>
        )
    }

    return (
        <PublicLayout>
            <div className="max-w-[1400px] flex flex-col w-full items-start mx-auto my-12 px-4 overflow-y-auto md:px-12"> {/* Container principale */}
                <h1 className="text-4xl font-medium mb-12 bodoni">{TextDictionary.Carrello_Checkout.TitoloCarrello}</h1>
                <div className="w-full flex flex-col gap-4 md:flex-row"> {/* Contenuto */}
                    <div className="w-full md:w-3/5 border border-black border-opacity-20 flex flex-col"> {/* Prodotti */}
                        {/* Header */}
                        <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelProdotto}</p>
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                        </div>

                        {/* Lista prodotti, composta da un componente per ogni prodotto */}
                        <div className="flex flex-col">
                            {cartItems.map((product: Course, index: number) => (
                                <ProductCard key={product.ID} {...product} />
                            ))}
                        </div>
                    </div>
                    <div className="w-full md:w-2/5 h-fit border border-black border-opacity-20 flex flex-col gap-4 p-4"> {/* Sidebar */}
                        <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelRiepilogo}</p>

                        {/* Codice sconto */}
                        <div className="flex flex-col lg:flex-row gap-4 pb-4">
                            <input type="text" placeholder="Codice promozionale" className="border border-black border-opacity-20 p-2 flex-1"
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') handlePromoCode();
                                }}
                                disabled={getPromoCode() !== ''}
                                style={{ opacity: getPromoCode() !== '' ? 0.5 : 1, cursor: getPromoCode() !== '' ? 'not-allowed' : 'initial' }}
                            />
                            <button className="bg-primary text-white text-lg font-medium py-2 px-8"
                                onClick={handlePromoCode}
                                disabled={getPromoCode() !== ''}
                                style={{ opacity: getPromoCode() !== '' ? 0.5 : 1, cursor: getPromoCode() !== '' ? 'not-allowed' : 'pointer' }}
                            >{TextDictionary.Carrello_Checkout.BottoneApplica}</button>
                        </div>

                        {/* Messaggio codice sconto con animazione che scompare dopo 3 secondi */}
                        {couponMessage && <div className={`flex flex-row items-center justify-between border px-4 py-2 ${!isValidCoupon ? 'border-red-500 bg-red-500 bg-opacity-10' : 'bg-successGreen bg-opacity-10 border-successGreen'}`}>
                            <p className="text-sm font-light">{couponMessage}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-black cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                onClick={() => setCouponMessage('')}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>}

                        {/* Subtotale */}
                        <div className="flex flex-row justify-between items-center pt-4">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelSubtotale}</p>
                            <p className="text-lg font-semibold">{cartTotalInfo.SubTotal?.toFixed(2)} €</p>
                        </div>

                        {
                            cartTotalInfo.SpecialDiscount > 0 && (
                                <div className="flex items-center justify-between">
                                    <p className="text-md italic">{TextDictionary.Carrello_Checkout.LabelScontoSpeciale}</p>
                                    <p className="text-md">- {cartTotalInfo.SpecialDiscount?.toFixed(2)} €</p>
                                </div>
                            )
                        }

                        {cartTotalInfo.Coupons && (
                            Object.keys(cartTotalInfo.Coupons).map((key: any, index: number) => {
                                if (key !== 'Total') return (
                                    <div className="flex items-center justify-between">
                                        <div className="flex flex-row gap-2 items-center">
                                            <button className="text-xl text-red-500" onClick={() => {
                                                applyPromoCode('')
                                                setPromoCode('');

                                                setCartTotalInfo({ ...cartTotalInfo, Coupons: [] });
                                            }}>x</button>
                                            <p className="text-md italic">{key}</p>
                                        </div>
                                        <p className="text-md">- {cartTotalInfo.Coupons[key]?.toFixed(2)} €</p>
                                    </div>
                                );
                            })
                        )}

                        {cartTotalInfo.VAT > 0 && (
                            <div className="flex items-center justify-between">
                                <p className="text-md">{TextDictionary.Carrello_Checkout.LabelIVA}</p>
                                <p className="text-md">{cartTotalInfo.VAT?.toFixed(2)} €</p>
                            </div>
                        )}


                        {/* Divisore */}
                        <div className="border-b border-black border-opacity-20"></div>

                        {/* Spedizione */}

                        {/* Totale */}
                        <div className="flex flex-row justify-between items-center pb-8">
                            <p className="text-2xl font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                            <p className="text-2xl font-semibold">{cartTotalInfo.Total?.toFixed(2)} €</p>

                        </div>


                        {/* Vai al pagamento */}
                        <button className="bg-primary text-white text-lg font-medium py-2 px-8"
                            onClick={() => {
                                if (AuthService.getInstance().accessToken)
                                    handleBeginCheckout()
                                else
                                    navigate('/signin') // Aggiungere redirect dopo il login che riporta a /cart
                            }}
                            disabled={cartItems.length === 0 || cartTotalInfo.Total === 0 || isProcessingCheckout}
                            style={{ opacity: cartItems.length === 0 || cartTotalInfo.Total === 0 || isProcessingCheckout ? 0.5 : 1, cursor: cartItems.length === 0 || cartTotalInfo.Total === 0 || isProcessingCheckout ? 'not-allowed' : 'pointer' }}
                        >{TextDictionary.Carrello_Checkout.BottoneProcediPagamento}</button>

                        <div className="border-b border-black border-opacity-20"></div>

                        {/* Informazioni aggiuntive */}
                        <div className="flex flex-col gap-2">
                            <p className="text-lg font-medium mb-4 mt-2">{TextDictionary.Carrello_Checkout.LabelInformazioniAggiuntive}</p>
                            <AdditionalInfo
                                title={TextDictionary.Carrello_Checkout.SicurezzaPagamenti}
                                content={TextDictionary.Carrello_Checkout.DescrizioneSicurezzaPagamenti}
                                icon={<GoLock />}
                            />
                            <i className="fa fa-address-book" aria-hidden="true"></i>

                            <AdditionalInfo
                                title={TextDictionary.Carrello_Checkout.FatturazioneAutomatica}
                                content={TextDictionary.Carrello_Checkout.DescrizioneFatturazioneAutomatica}
                                icon={<LiaFileInvoiceSolid />}

                            />
                            <AdditionalInfo
                                title={TextDictionary.Carrello_Checkout.SupportoDedicato}
                                content={TextDictionary.Carrello_Checkout.DescrizioneSupportoDedicato}
                                icon={<PiHeadset />}
                            />
                            <AdditionalInfo
                                title={TextDictionary.Carrello_Checkout.AccessoImmediato}
                                content={TextDictionary.Carrello_Checkout.DescrizioneAccessoImmediato}
                                icon={<CiPlay1 />}
                            />
                            <AdditionalInfo
                                title={TextDictionary.Carrello_Checkout.RisparmioAbbonamenti}
                                content={TextDictionary.Carrello_Checkout.DescrizioneRisparmioAbbonamenti}
                                icon={<MdOutlineSubscriptions />}
                            />
                            <AdditionalInfo
                                title={TextDictionary.Carrello_Checkout.InformazioniFiscali}
                                content={TextDictionary.Carrello_Checkout.DescrizioneInformazioniFiscali}
                                icon={<HiOutlineReceiptTax />}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Cart;