import { useEffect } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { TextDictionary } from "../../../utils/TextDictionary";
import { TableComponent } from "../../../components/TableComponent";
import { Link } from "react-router-dom";


const Coupons = () => {
    return (
        <AdminLayout>
            <div className="relative flex flex-col w-full h-full">
                <div className="flex flex-row justify-between w-full mb-8">
                    <h1 className="text-3xl font-semibold">{TextDictionary.Admin.Coupons.Titolo}</h1>
                    <button className="bg-primary text-white font-medium py-2 px-4 rounded">
                        <Link to="/admin/coupons/new">{TextDictionary.Admin.Coupons.NuovoCoupon}</Link>
                    </button>
                </div>
                <TableComponent
                    endpoint="/coupons"
                    dictionaryContext={"Coupon"}
                />
            </div>
        </AdminLayout>
    );
};

export default Coupons;