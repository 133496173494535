import { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import DropdownUser from "./DropdownUser";
import { AppConfig } from "../config/Configuration";
import { TextDictionary } from "../utils/TextDictionary";
import { AuthService } from "../auth/sso/auth.service";
import { PrimaryButton } from "./PrimaryButton";
import { CartContext } from "../core/cartProvider";

interface HeaderMobileProps {
    isUserSetted: boolean;
    isAdminArea: boolean;
}

const HeaderMobile = ({ isUserSetted, isAdminArea }: HeaderMobileProps) => {
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);
    const [coursesDropdownOpen, setCoursesDropdownOpen] = useState(false);
    const { getCartLength, clearCart } = useContext(CartContext);

    const dialogMenu = () => {
        return (
            <div className="fixed top-0 left-0 w-full h-full bg-primary z-50">
                <div className="flex justify-end pt-8 pr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="white" onClick={() => setMenuOpen(false)}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div className="flex flex-col items-center justify-center h-full">
                    <div className="flex flex-col gap-6 text-center items-center">
                        <span className="text-white text-2xl font-medium cursor-pointer" onClick={() => {
                            navigate('/');
                            setMenuOpen(false);
                        }}>
                            <svg fill="white" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px" height="24px" viewBox="0 0 495.398 495.398"
                                xmlSpace="preserve">
                                <g>
                                    <g>
                                        <g>
                                            <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391
                                        v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158
                                        c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747
                                        c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"/>
                                            <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401
                                        c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79
                                        c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>

                        {
                            AuthService.getInstance().accessToken ? (
                                <span className="text-white text-2xl font-medium cursor-pointer" onClick={() => {
                                    navigate('/profile');
                                    setMenuOpen(false);
                                }}>{TextDictionary.Header.VoceProfilo}</span>
                            ) : (
                                <span className="text-white text-2xl font-medium cursor-pointer" onClick={() => {
                                    navigate('/signin');
                                    setMenuOpen(false);
                                }}>{TextDictionary.Header.VoceAccedi}</span>
                            )
                        }

                        <span className="text-white text-2xl font-medium cursor-pointer" onClick={() => {
                            navigate('/public/corsi/live');
                            setMenuOpen(false);
                        }}>{TextDictionary.Header.VoceCorsiLive}</span>
                        <span className="text-white text-2xl font-medium cursor-pointer" onClick={() => {
                            navigate('/public/corsi');
                            setMenuOpen(false);
                        }}>{TextDictionary.Header.VoceCorsiMobile}</span>
                        <span className="text-white text-2xl font-medium cursor-pointer" onClick={() => {
                            navigate('/public/abbonamenti');
                            setMenuOpen(false);
                        }}>{TextDictionary.Header.VoceScopriAbbonamenti}</span>
                        <span className="text-white text-2xl font-medium cursor-pointer" onClick={() => {
                            window.open('https://carbognani.srl/international-news', '_blank');
                            setMenuOpen(false);
                        }}>{TextDictionary.Header.VoceInternationalNews}</span>
                        <span className="text-white text-2xl font-medium cursor-pointer" onClick={() => {
                            window.open('https://carbognani.srl/', '_blank');
                            setMenuOpen(false);
                        }}>{TextDictionary.Header.VoceStudioCarbognani}</span>

                        <div className="relative">
                            <Link to="/cart">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38754 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6M10 21C10 21.5523 9.55229 22 9 22C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20C9.55229 20 10 20.4477 10 21ZM21 21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21C19 20.4477 19.4477 20 20 20C20.5523 20 21 20.4477 21 21Z" stroke="#F3F3F3" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </Link>
                            {
                                getCartLength() === 0 ? (
                                    null
                                ) : (
                                    <div className="absolute -top-4 -right-4 bg-white text-primary rounded-full w-5 h-5 flex items-center justify-center font-medium text-xs">
                                        {getCartLength()}
                                    </div>
                                )
                            }
                        </div>

                        {/* {
                            AuthService.getInstance().accessToken && (
                                <PrimaryButton
                                    text={TextDictionary.Header.VoceLogout}
                                    onClick={() => {
                                        AuthService.getInstance().signOut();
                                        clearCart();
                                        setMenuOpen(false);
                                        navigate('/');
                                    }}
                                />
                            )
                        } */}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <header className="sticky top-0 flex w-full bg-primary border-0 justify-between items-center h-[100px] lg:hidden py-4 gap-2" style={{ zIndex: 999 }}>
            {menuOpen && dialogMenu()}
            <img src={AppConfig.brandLogo} alt="logo" className="h-16 cursor-pointer" onClick={() => {
                navigate('/');
            }} />
            <input type="text" placeholder="Cerca" className="px-4 mx-2 py-1 flex-1 rounded-full bg-transparent text-white outline-none border border-white" onKeyUp={
                (e: any) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        let text = e.target.value;
                        // /public/corsi?search=:search
                        navigate(`/public/corsi?search=${text}`);
                    }
                }
            } />
            <div className="mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="white" onClick={() => setMenuOpen(!menuOpen)}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
            </div>
        </header>
    )
};

export default HeaderMobile;
