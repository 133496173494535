import { useNavigate, useParams } from "react-router-dom";
import IDPLayout from "../../layout/IDPLayout";
import { TextDictionary } from "../../utils/TextDictionary";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { axiosAuthPublicInstance } from "../../auth/sso/auth.interceptor";
import { ApiConfig } from "../../config/Configuration";
import { axiosLoginInstance } from "../../auth/sso/login.interceptor";
import { useForm } from "react-hook-form";
import { User } from '../../auth/sso/auth.types';
import PublicLayout from "../../layout/PublicLayout";

export const ResetPassword = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<User>({
        defaultValues: {
            Password: '',
            ConfirmPassword: ''
        }
    });
    const [token, setToken] = useState<string | null>(null);

    const onSubmit = (data: User) => submitResetPassword(data);

    const submitResetPassword = async (data: User) => {
        if (data.Password !== data.ConfirmPassword) {
            Swal.fire({
                title: 'Errore in fase di reset password!',
                text: 'Le password non corrispondono',
                icon: 'error'
            });
            return;
        }

        if (!token) {
            Swal.fire({
                title: 'Errore in fase di reset password!',
                text: 'Token non presente',
                icon: 'error'
            });
            return;
        }

        const payload = {
            Token: token,
            Password: data.Password
        };

        try {
            const response = await axiosLoginInstance.post(`${ApiConfig.ROOT_URL}/auth/password-reset`, payload);
            if (response.status === 200) {
                Swal.fire({
                    title: 'Password resettata con successo!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });

                // Reindirizzo alla login
                setTimeout(() => {
                    navigate('/signin');
                }, 1000);
            }
        } catch (error: any) {
            console.error("---- reset password error", error);

            Swal.fire({
                title: 'Errore in fase di reset della password!',
                text: error.response.data.message,
                icon: 'error'
            });
        }
    };

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        setToken(params.get('token'));
    }, []);

    return (
        <PublicLayout>
            <div className='h-full mx-auto max-w-screen-md md:p-6 flex flex-col items-center justify-center'>
                <div className="w-full py-14 px-6 md:px-0 md:py-0">
                    <span className="mb-1.5 block font-medium bodoni">
                        {TextDictionary.PagineIDP.Sottotitolo}
                    </span>
                    <h2 className="mb-9 text-3xl font-bold text-black">
                        {TextDictionary.PagineIDP.TitoloResetPassword}
                    </h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-8">
                            <label className="mb-2.5 block font-medium text-black ">
                                {TextDictionary.PagineIDP.CampoPassword}
                            </label>
                            <div className="relative">
                                <input
                                    type="password"
                                    className="w-full px-4 py-3 border border-gray-300 rounded-md"
                                    placeholder={TextDictionary.PagineIDP.CampoPassword}
                                    {...register('Password', { required: true })}
                                />
                                {errors.Password && <span className="text-red-500">{TextDictionary.PagineIDP.CampoObbligatorio}</span>}
                            </div>
                        </div>

                        <div className="mb-8">
                            <label className="mb-2.5 block font-medium text-black ">
                                {TextDictionary.PagineIDP.CampoConfermaPassword}
                            </label>
                            <div className="relative">
                                <input
                                    type="password"
                                    className="w-full px-4 py-3 border border-gray-300 rounded-md"
                                    placeholder={TextDictionary.PagineIDP.CampoConfermaPassword}
                                    {...register('ConfirmPassword', { required: true })}
                                />
                                {errors.ConfirmPassword && <span className="text-red-500">{TextDictionary.PagineIDP.CampoObbligatorio}</span>}
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                        >
                            {TextDictionary.PagineIDP.BottoneModificaPassword}
                        </button>
                    </form>
                </div>
            </div>
        </PublicLayout>
    )
};