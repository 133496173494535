import { TextDictionary } from "../utils/TextDictionary";

const Footer = () => {
    return (
        <footer className="relative bottom-0 flex w-full bg-primary justify-center">
            <div className="flex flex-col lg:flex-row flex-grow items-center justify-between py-10 px-4 md:px-6 2xl:px-11 max-w-[1400px]">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 447.48 375.75" fill="white" className="h-16">
                    <g>
                        <g id="Livello_1">
                            <path d="M167.64,183.22c-29.64,0-53.76-24.11-53.76-53.76s24.11-53.76,53.76-53.76c14.36,0,27.86,5.59,38.01,15.74l1.87,1.87-3.73,3.74-1.87-1.87c-9.15-9.15-21.33-14.2-34.27-14.2-26.73,0-48.47,21.75-48.47,48.47s21.75,48.47,48.47,48.47c12.06,0,23.45-4.38,32.36-12.38l-4.79-4.78c-7.62,6.73-17.31,10.41-27.57,10.41-23,0-41.71-18.71-41.71-41.71s18.71-41.72,41.71-41.72c11.14,0,21.62,4.34,29.5,12.22,1.03,1.03,1.03,2.7,0,3.73l-8.51,8.51c-.5.5-1.17.77-1.87.77s-1.37-.28-1.87-.77c-4.61-4.61-10.73-7.14-17.25-7.14-13.45,0-24.39,10.94-24.39,24.39s10.94,24.39,24.39,24.39c6.51,0,12.64-2.54,17.25-7.14l1.87-1.87,3.73,3.73-1.87,1.87c-5.61,5.6-13.06,8.69-20.98,8.69-16.36,0-29.68-13.31-29.68-29.67s13.31-29.68,29.68-29.68c7.04,0,13.7,2.44,19.03,6.91l4.79-4.79c-6.62-5.74-14.98-8.87-23.83-8.87-20.09,0-36.43,16.34-36.43,36.43s16.34,36.43,36.43,36.43c9.73,0,18.88-3.79,25.76-10.67,1.03-1.03,2.7-1.03,3.74,0l8.51,8.51c.5.5.77,1.17.77,1.87s-.28,1.37-.77,1.87c-10.15,10.15-23.65,15.75-38.01,15.75" />
                            <path d="M307.53,221.81c-1.64-5.16-4.33-9-8.06-11.52-3.73-2.52-7.95-3.79-12.67-3.79s-8.64,1.26-12.43,3.79c-3.79,2.52-6.5,6.37-8.15,11.52l-30.12,96.93h16.95l8.06-26.84,51.41-.14,8.01,26.98h17.11l-30.12-96.93ZM265.72,276.61l14.99-49.86c.99-3.4,3.08-5.1,6.26-5.1s5.16,1.7,6.26,5.1l14.8,49.86h-42.31Z" />
                            <path d="M218.49,319.51h0c-57.78,0-108.99-39.34-108.99-108.99v-12.11s108.99,0,108.99,0v121.1Z" />
                            <g>
                                <rect x="233.02" y="115.58" width="57.32" height="30.4" />
                                <rect x="233.02" y="152.79" width="57.32" height="29.35" />
                                <polygon points="233.02 76.78 233.02 107.19 290.33 107.19 290.33 76.78 334.55 76.78 334.55 174.77 334.55 182.15 338.95 182.15 338.95 152.79 338.95 145.98 338.95 115.58 338.95 107.19 338.95 79.85 338.95 76.78 233.02 76.78" />
                                <polygon points="290.33 182.15 334.55 182.15 311.36 157.3 290.33 182.15" />
                            </g>
                        </g>
                    </g>
                </svg>

                <div className="flex flex-col items-center text-white text-sm gap-3 text-center mt-8 lg:mt-0">
                    <span>{TextDictionary.Footer.Copyright}</span>

                    {/* creo una griglia di 3 colonne */}
                    <div className="grid grid-cols-3 gap-4 mb-4">
                        <a href="/public/corsi/registrati" className="underline">{TextDictionary.Footer.CorsiRegistrati}</a> 
                        <a href="/public/corsi/live" className="underline">{TextDictionary.Footer.CorsiLive}</a>
                        <a href="/public/corsi/evidenza" className="underline">{TextDictionary.Footer.CorsiEvidenza}</a>
                    </div>

                    <a href="https://carbognani.srl/termini-e-condizioni" target="_blank" rel="noreferrer" className="underline">{TextDictionary.Footer.TerminiCondizioni}</a>

                    <span>{TextDictionary.Footer.PoweredBy} <a href="https://bbsway.it/agency" target="_blank" rel="noreferrer" className="underline">{TextDictionary.Footer.TheBBsWay}</a></span>
                </div>

                {/* icona di linkedin */}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="white"
                    className="cursor-pointer mt-8 lg:mt-0"
                    onClick={() => window.open('https://www.linkedin.com/company/studio-toscano-carbognani/about/?viewAsMember=true', '_blank')}
                ><path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path><path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path></svg>
            </div>
        </footer>
    );
};

export default Footer;