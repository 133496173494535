import { Link } from "react-router-dom";
import IDPLayout from "../../layout/IDPLayout";
import { TextDictionary } from "../../utils/TextDictionary";
import { useForm } from "react-hook-form";
import { User } from "../../models/user";
import { axiosLoginInstance } from "../../auth/sso/login.interceptor";
import { ApiConfig } from "../../config/Configuration";
import Swal from "sweetalert2";
import PublicLayout from "../../layout/PublicLayout";

export const ForgotPassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<User>({
        defaultValues: {
            Email: '',
        }
    });

    const onSubmit = (data: User) => submitForgotPassword(data);

    const submitForgotPassword = async (data: User) => {
        // Implementare il recupero password
        const payload = {
            Email: data.Email
        };

        // 1. /auth/password-forgot 
        // 2. /auth/password-reset CON PAYLOAD Token e nuova password

        try {
            const response = await axiosLoginInstance.post(`${ApiConfig.ROOT_URL}/auth/password-forgot`, payload);
            if (response.status === 200) {
                Swal.fire({
                    title: "Richiesta inviata con successo!",
                    text: "Controlla la tua casella di posta per il reset della password.",
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error: any) {
            console.error("---- forgot password error", error);
            Swal.fire({
                title: 'Errore in fase di recupero password!',
                text: error.response.data.message,
                icon: 'error'
            });
        }
    }

    return (
        <PublicLayout>
            <div className='h-full mx-auto max-w-screen-md md:p-6 flex flex-col items-center justify-center'>
                <div className="w-full py-14 px-6 md:px-0 md:py-0">
                    <span className="mb-1.5 block font-medium bodoni">
                        {TextDictionary.PagineIDP.Sottotitolo}
                    </span>
                    <h2 className="mb-9 text-3xl font-bold text-black">
                        {TextDictionary.PagineIDP.TitoloRecuperoPassword}
                    </h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-8">
                            <label className="mb-2.5 block font-medium text-black ">
                                {TextDictionary.PagineIDP.CampoEmail}
                            </label>
                            <div className="relative">
                                <input
                                    type="email"
                                    placeholder="Inserisci la tua email"
                                    className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                    {...register('Email', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                                />
                                {errors.Email && <p className="absolute text-errorRed text-sm">{errors.Email.message}</p>}
                            </div>
                        </div>

                        <div className="mb-5">
                            <input
                                type="submit"
                                value="Invia richiesta"
                                className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                            />
                        </div>

                        <div className="mt-6 flex flex-col md:flex-row gap-4 md:gap-0 justify-between">
                            <p>
                                {TextDictionary.PagineIDP.BottoneNonHaiAccount}{' '}
                                <Link to="/signup" className="text-primary underline">
                                    {TextDictionary.PagineIDP.BottoneRegistrati}
                                </Link>
                            </p>
                            <p>
                                <Link to="/signin" className="text-primary">
                                    {TextDictionary.PagineIDP.BottoneHaiAccount}
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>

        </PublicLayout>
    );
};
