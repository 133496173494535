import React, { useState, useEffect } from 'react';
import { createGrid, GridApi, GridOptions } from 'ag-grid-community';

interface NewDataRelationHandlerComponentProps {
    options: Options;
    setNewElementsData: (newElements: string[]) => void;
    newElementsData: string[];
}

interface Options {
    leftLabel: string;
    rightLabel: string;
    noDataMessage: string;
    columnName: string;
    control?: any;
}

const NewDataRelationHandlerComponent = ({ options, setNewElementsData, newElementsData }: NewDataRelationHandlerComponentProps) => {
    const [newElements, setNewElements] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        if (newElementsData && newElementsData.length > 0)
            setNewElements(newElementsData);
    }, [newElementsData]);

    useEffect(() => {
        if (!newElements || newElements.length === 0) {
            if (gridApi) {
                gridApi.updateGridOptions({ rowData: [] });
            }
            return;
        }
        createTable();
    }, [newElements]);

    const createTable = () => {
        if (newElements.length === 0) {
            return;
        }

        const columnDefs = [
            {
                headerName: options.columnName,
                field: 'name',
                cellRenderer: (params: any) => {
                    const container = document.createElement('div');
                    container.className = 'flex items-center justify-between w-full';

                    const nameSpan = document.createElement('span');
                    nameSpan.innerText = params.value;

                    const removeButton = document.createElement('button');
                    removeButton.className = 'bg-[#f5584d] text-white text-sm px-6 py-1 rounded-full hover:bg-transparent hover:text-errorRed border border-[#f5584d]';
                    removeButton.innerText = 'Elimina';
                    removeButton.addEventListener('click', () => {
                        setNewElements((prevElements) => {
                            const updatedElements = prevElements.filter((el) => el !== params.value);
                            setNewElementsData(updatedElements); // Aggiorna anche newElementsData
                            return updatedElements; // Aggiorna newElements
                        });
                    });

                    container.appendChild(nameSpan);
                    container.appendChild(removeButton);

                    return container;
                }
            }
        ];

        const rowData = newElements.map((item) => ({ name: item }));

        const gridOptions = {
            columnDefs,
            rowData,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            overlayNoRowsTemplate: options.noDataMessage,
            autoSizeStrategy: {
                type: "fitGridWidth",
            },
        } as GridOptions;

        gridOptions.pagination = true;
        gridOptions.paginationPageSize = 10;
        gridOptions.paginationPageSizeSelector = [10];

        const myGrid = document.getElementById('newDataTable') as HTMLElement;
        if (myGrid) {
            myGrid.innerHTML = '';
        }

        const grid = createGrid(myGrid, gridOptions);
        setGridApi(grid);
    };

    useEffect(() => {
        createTable();
    }, [newElements]);

    const handleAddElement = () => {
        if (!inputValue) {
            return;
        }

        // controllo se l'elemento è un'email valida
        const regex = new RegExp("^(?:[a-zA-Z0-9._%+-]+|\\*)@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$");
        if (!regex.test(inputValue)) {
            setErrorMessage('Inserisci un indirizzo email valido');
            return;
        } else setErrorMessage('');

        // controllo se l'elemento è già presente
        if (newElements.includes(inputValue)) {
            setErrorMessage('Elemento già presente');
            return;
        } else setErrorMessage('');


        setNewElements([...newElements, inputValue]); // aggiungo l'elemento alla lista
        setNewElementsData([...newElements, inputValue]); // aggiungo l'elemento alla lista
        setInputValue(''); // resetto l'input
    }

    return (
        <div className="module-card flex flex-row w-full gap-4 items-start h-96 pb-8">
            <div className="w-1/2 pr-10 h-full">
                <span className="text-md font-semibold mb-2">{options.leftLabel}</span>
                {
                    newElements.length === 0 && !gridApi ? (
                        <p className='mt-4'>{options.noDataMessage}</p>
                    ) : null
                }
                <div id="newDataTable" className="ag-theme-quartz h-full w-full mt-4 pb-8" />
            </div>
            <div className="w-1/2 h-full">
                <span className="text-md font-semibold mb-2">{options.rightLabel}</span>
                <div className="flex flex-row gap-4 mt-4 mb-4">
                    <input type="text" className="border border-black border-opacity-10 w-full px-2 py-1" onChange={(e) =>
                        setInputValue(e.target.value)
                    } onKeyUp={
                        (e) => {
                            if (e.key === 'Enter') {
                                handleAddElement();
                            }
                        }
                    } value={inputValue} />
                    <button className="bg-primary text-white px-4 py-2 rounded-lg text-xl"
                        onClick={() => {
                            handleAddElement();
                        }}
                    >+</button>
                </div>
                {
                    errorMessage ? (
                        <span className="text-errorRed text-sm">{errorMessage}</span>
                    ) : null
                }
            </div>
        </div>
    );
};

export default NewDataRelationHandlerComponent;