import { useEffect } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link } from "react-router-dom";
import { TableComponent } from "../../../components/TableComponent";
import { TextDictionary } from "../../../utils/TextDictionary";


const Orders = () => {
    return (
        <AdminLayout>
            <div className="relative flex flex-col w-full h-full">
                <div className="flex flex-row justify-between w-full mb-8">
                    <h1 className="text-3xl font-semibold">{TextDictionary.Admin.Ordini.Titolo}</h1>
                </div>
                <TableComponent
                    endpoint="/orders"
                    dictionaryContext={"Ordini"}
                />
            </div>
        </AdminLayout>
    );
};

export default Orders;