export const TextDictionary = {

    "AppName": 'Carbognani Custom Academy',

    "Home": {
        "Titolo": "Academy",
        "Contenuto": "Accedi a <b>corsi professionali</b> su misura per le tue esigenze, con la libertà di seguire <b>lezioni live o registrate</b>, mettendo in pausa o saltando capitoli come preferisci.",
        "CTA_Formazione": "Inizia la formazione",
        "CTA_Landing": "Come funziona ",
        "CorsiEvidenza": "Corsi e live in evidenza",
        "ProssimiCorsi": "Prossime live",
        "ScopriTuttiCorsi": "Scopri tutti i corsi e le live",
        "NessunCorso": "Nessun corso disponibile",
        "Vantaggi": "I vantaggi di Academy",
        "VantaggiContenuto": "Tutto ciò che ti serve per crescere in un’unica soluzione",


        "TitoloFlessibilita": "Flessibilità assoluta",
        "ContenutoFlessibilita": "Visualizza i corsi live o in modalità on demand, quando ti è più comodo, permettendoti di conciliare gli impegni professionali con la formazione.",
        "TitoloAreaRiservata": "Area riservata personalizzata",
        "ContenutoAreaRiservata": "Accedi a tutti i tuoi corsi in un unico luogo, monitora i progressi, riprendi dove hai interrotto e gestisci facilmente il tuo percorso formativo.",
        "TitoloApprendimento": "Apprendimento mirato",
        "ContenutoApprendimento": "Alla fine di ogni capitolo, potrai completare test per verificare la tua comprensione e consolidare le tue competenze.",
        "TitoloCertificati": "Certificati e crediti formativi",
        "ContenutoCertificati": "Ottieni attestati di partecipazione che potrai utilizzare per dimostrare le tue nuove competenze, e guadagna crediti formativi riconosciuti.",
        "TitoloSupporto": "Supporto continuo",
        "ContenutoSupporto": "Il nostro team è disponibile per assisterti in ogni fase del tuo percorso formativo, garantendo un’esperienza senza intoppi.",

        "CTA_RichiediInformazioni": "Richiedi informazioni",
    },

    "Footer": {
        "Copyright": "© 2022 Studio Carbognani srl | P.Iva 02085250344 | tutti i diritti riservati",
        "TerminiCondizioni": "Termini e condizioni",
        "PoweredBy": "Powered by",
        "TheBBsWay": "The BB's Way",

        "CorsiRegistrati": "Corsi registrati",
        "CorsiLive": "Corsi live",
        "CorsiEvidenza": "Corsi in evidenza",
    },
    "Profilo": {
        "Titolo": "Profilo personale",
        "MioProfilo": "Il mio profilo",
        "MieiCorsi": "I miei corsi",
        "Certificati": "Certificati",
        "GestioneAbbonamenti": "Gestione abbonamenti",
    },
    "TemplateCertificato": {
        "Titolo": "Attestato di conseguimento corso",
        "Riconoscimento": "Il seguente riconoscimento va a:",
        "SubDescrizione": "Per aver completato con successo il corso di formazione"
    },
    "Abbonamenti": {
        "Titolo": "I migliori corsi di aggiornamento\n e formazione su un’unica\n piattaforma. ",
        "Sottotitolo": "Abbonati a Carbognani Custom Academy, il servizio per assistere a tutti i corsi di formazione.",
        "TerminiCondizioni": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac tristique ex. Nunc lacinia egestas est ac finibus. Mauris dictum mollis libero et semper. Morbi eu nisi porttitor, aliquam eros eget, blandit justo. Morbi elementum odio at tellus facilisis, eget posuere metus lobortis. In hac habitasse platea dictumst. Suspendisse consequat vel libero eget sodales. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac tristique ex. Nunc lacinia egestas est ac finibus. Mauris dictum mollis libero et semper. Morbi eu nisi porttitor, aliquam eros eget, blandit justo. Morbi elementum odio at tellus facilisis, eget posuere metus lobortis. In hac habitasse platea dictumst. Suspendisse consequat vel libero eget sodales. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac tristique ex. Nunc lacinia egestas est ac finibus. Mauris dictum mollis libero et semper. Morbi eu nisi porttitor, aliquam eros eget, blandit justo. Morbi elementum odio at tellus facilisis, eget posuere metus lobortis. In hac habitasse platea dictumst. Suspendisse consequat vel libero eget sodales. ",
        "LabelAlMese": "mese",
        "NonHaiPermessi": "Non hai i permessi per acquistare questo abbonamento. Contatta il tuo amministratore per maggiori informazioni.",
    },
    "ListaCorsi": {
        "Titolo": "Scopri tutti \ni corsi",
        "Sottotitolo": "Corsi di formazione online \nriconosciuti a livello europeo!",
        "CreaAccountGratis": "Registrati",
        "BottoneAcquista": "Acquista ora!",
        "BottoneAbbonati": "Abbonati ora!",
        "SeiGiaAbbonato": "Sei già abbonato",
        "ModificaCorso": "Modifica ✏️",
        "BottoneGuardaLive": "GUARDA LIVE!",
        "BottoneGuardaCorso": "Guarda il corso!",
        "BottoneVaiAlCorsoCollegato": "Vai al corso collegato",
        "BottoneAccediPerVedere": "Accedi per vedere la live!",
        "ScopriCorso": "Scopri il corso",
        "TestoRegistratiPerAcquistare": "Sei già registrato? Effettua il ",
        "LiveNonAncoraDisponibile": "La live sarà disponibile a breve",
        

        "LabelLezioni": "Lezioni:",
        "LabelData": "Data:",
        "LabelDurata": "Durata:",
        "LabelDocente": "DOCENTE:",
        "LabelConAbbonamento": "o gratuito con abbonamento",


        "LabelDescrizioneCorso": "Contenuto",
        "LabelCosaComprende": "Cosa troverai nel corso",
        "LabelLezioniNelCorso": "Lezioni",

        "CorsiInEvidenza": "Corsi in evidenza",

        "NessunaRisorsa": "Nessun materiale collegato al corso",
        "NessunaDescrizione": "Nessuna descrizione disponibile",

        "NessunRisultato": "Nessun risultato",

        "VaiAlleDomande": "Vai alle domande",
    },
    "PaginaVideo": {
        "BottoneIniziaQuiz": "Inizia il quiz",
        "LabelAggiornatoAl": "AGGIORNATO AL:",
        "LabelDocente": "DOCENTE:",
        "LabelDocenteLezione": "DOCENTE DELLA LEZIONE:",
        "BottoneIniziaTest": "FAI IL TEST",
        "BottoneQuizCompletato": "Quiz completato con successo",
        "BottoneVisualizzaCertificato": "Visualizza certificato",
        "BottoneProssimaUnita": "Passa alla prossima unità",
        "LabelIntroduzione": "Di cosa parleremo in questa lezione",
        "LabelRisorse": "Scarica il materiale utile per il tuo corso",
        "BottoneScaricaRisorse": "Scarica materiale",
        "LabelRiepilogo": "Riepilogo del corso",
        "LabelGuardaIlVideo": "Per sbloccare il test completare la visione della lezione",

        "LabelRisorseLezione": "Materiale didattico della lezione",
        "LabelNessunaRisorsaLezione": "Nessun materiale collegato alla lezione",
        "LabelRisorseCorso": "Materiale didattico del corso",
        "LabelNessunaRisorsaCorso": "Nessun materiale collegato al corso",
    },

    "PaginaLive": {
        "BottoneIniziaQuiz": "Inizia il quiz",
        "LabelAggiornatoAl": "DATA:",
        "LabelDocente": "DOCENTE:",
        "LabelDocenteLezione": "DOCENTE DELLA LIVE:",
        "BottoneIniziaTest": "FAI IL TEST",
        "BottoneProssimaUnita": "Passa alla prossima unità",
        "LabelIntroduzione": "Di cosa parleremo in questa live",
        "LabelRisorse": "Scarica il materiale utile per il tuo corso",
        "BottoneScaricaRisorse": "Scarica materiale",
        "LabelRiepilogo": "Riepilogo del corso",

        "LabelRisorseLezione": "Materiale didattico della live",
        "LabelNessunaRisorsaLezione": "Nessun materiale collegato alla live",
        "LabelRisorseCorso": "Materiale didattico del corso",
        "LabelNessunaRisorsaCorso": "Nessun materiale collegato al live",
    },

    "Carrello_Checkout": {
        "Inizializzazione": "Caricamento in corso...",

        "TitoloCarrello": "Carrello",
        "TitoloCheckout": "Checkout",
        "LabelProdotto": "Prodotto",
        "LabelTotale": "Totale",
        "LabelIVA": "IVA 22%",
        "LabelRiepilogo": "Riepilogo",
        "BottoneApplica": "Applica",
        "BottoneRimuoviProdotto": "Rimuovi prodotto",
        "LabelSubtotale": "Subtotale",
        "PlaceholderCodicePromozionale": "Codice promozionale",
        "BottoneProcediPagamento": "Procedi al pagamento",


        "LabelInformazioniAggiuntive": "Informazioni aggiuntive",
        "SicurezzaPagamenti": "Sicurezza dei pagamenti",
        "DescrizioneSicurezzaPagamenti": "Tutti i pagamenti sono protetti e garantiti dai nostri sistemi di sicurezza.",
        "FatturazioneAutomatica": "Fatturazione automatica",
        "DescrizioneFatturazioneAutomatica": "Riceverai la fattura direttamente via email dopo la conferma del pagamento.",
        "SupportoDedicato": "Supporto dedicato",
        "DescrizioneSupportoDedicato": "Hai bisogno di aiuto? Il nostro team è a tua disposizione: <a href='mailto:academy@carbognani.srl'><u>academy@carbognani.srl</u></a> , tel: <a href='tel:0521289546'><u>0521289546</b></a>",
        "AccessoImmediato": "Accesso immediato",
        "DescrizioneAccessoImmediato": "Una volta completato il pagamento, riceverai immediatamente le credenziali per accedere ai tuoi corsi.",
        "RisparmioAbbonamenti": "Risparmia con gli abbonamenti",
        "DescrizioneRisparmioAbbonamenti": "Scegli un abbonamento per avere accesso a tutti i corsi live e registrati a un prezzo vantaggioso.",
        "InformazioniFiscali": "Informazioni fiscali",
        "DescrizioneInformazioniFiscali": "Tutti i prezzi non sono comprensivi di IVA. Per ulteriori dettagli consulta la sezione <a href='https://carbognani.srl/termini-e-condizioni' target='_blank'><u>Termini e condizioni d'uso</u></a>.",




        "LabelAbbonamento": "Abbonamento",
        "LabelScontoSpeciale": "Sconto speciale",

        "TornaAlCarrello": "Torna al carrello",

        "MessaggioCouponValido": "Coupon applicato correttamente",
        "MessaggioCouponNonValido": "Coupon non valido",
        "MessaggioCouponNonVerificabile": "Accedi per verificare questo coupon",

        "CouponApplicati": "Coupon applicato",

        "LabelDettagliDiFatturazione": "Dettagli di fatturazione",
        "LabelNoteSulTuoOrdine": "Note sul tuo ordine",

        "BottoneEffettuaOrdine": "Effettua ordine",

        "TestoNotaPagamento": "Completando l’acquisto accetto i termini e le condizioni di uso  visionabili a questo "
    },
    "ProfiloUtente": {
        "Titolo": "Il mio profilo",
        "Sottotitolo": "Gestisci i tuoi dati personali",
        "DatiPersonali": "Dati personali",
        "CambiaPassword": "Cambia password",
        "SalvaModifiche": "Salva modifiche",
        "Sicurezza": "Sicurezza",
        "Caricamento": "Caricamento in corso...",
        "Disconnetti": "Disconnetti",
    },
    "CorsiUtente": {
        "Titolo": "I miei corsi",
        "Sottotitolo": "Visualizza i corsi a cui sei iscritto",
        "NoCorsi": "Non possiedi nessun corso",
        "ScopriCorsi": "Scopri i corsi",
    },
    "CertificatiUtente": {
        "Titolo": "I miei certificati",
        "Sottotitolo": "Visualizza i certificati ottenuti",
    },
    "GestioneAbbonamenti": {
        "Titolo": "Gestione abbonamenti",
        "Sottotitolo": "Visualizza e gestisci i tuoi abbonamenti",

        "CTADipendentiAggiuntivi": "Vuoi più dipendenti?",
        "CTAAbbonamento": "Abbonati ora",
        "LabelMailTo": "Contattaci",

        "AbbonamentoAttivo": "Abbonamento attivo",
        "NoAbbonamento": "Non possiedi nessun abbonamento",
        "Scadenza": "Scadenza",
        "Proprietario": "Proprietario",
        "Dipendenti": "Dipendenti",
    },
    "TabelleDinamiche": {
        "Errore": "Attenzione!",
        "ErroreMessaggio": "Errore nel caricamento dei dati",
        "BottoneRiprova": "Riprova",

        "ConfermaEliminazione": "Sei sicuro di voler eliminare l'elemento?",
        "ConfermaSi": "Si",
        "ConfermaOk": "Ok",
        "ConfermaNo": "Annulla",

        "NessunRisultato": "Nessun risultato",
    },
    "DropdownUtente": {
        "VaiAlProfilo": "Vai al profilo",
        "MieiCorsi": "I miei corsi",
        "MieiCertificati": "Certificati ottenuti",
        "GestioneAbbonamenti": "Gestisci abbonamento",
        "AreaAmministrativa": "Area amministrativa",
        "Logout": "Logout",
        "MessaggioLogout": "Logout effettuato correttamente",
    },

    "Header": {
        "VoceMenu": "Menu",
        "VoceCorsi": "Corsi",
        "VoceCorsiMobile": "Tutti i corsi",
        "VoceCorsiLive": "Corsi live",
        "VoceHome": "Home",
        "LabelCategorie": "Categorie",
        "LabelCorsiRegistrati": "Corsi registrati",
        "LabelCorsiLive": "Corsi live",
        "LabelCorsiEvidenza": "Corsi in evidenza",
        "VoceDogana": "Dogana",
        "VoceImportExport": "Import / Export",
        "VoceTrasporti": "Trasporti",
        "VoceVediCorsi": "Vedi tutti i corsi",
        "VoceScopriAbbonamenti": "Abbonamenti",
        "VoceInternationalNews": "International News",
        "VoceNovita": "Novità",
        "VoceNews": "International News",
        "VoceStudioCarbognani": "Studio Carbognani",
        "VoceProfilo": "Vai al profilo",
        "VoceCarrello": "Carrello",
        "VoceRegistrati": "Crea un account",
        "VoceAccedi": "Accedi",
        "VoceLogout": "Logout",
    },

    "PagineIDP": {
        "Sottotitolo": "Carbognani Academy",
        "TitoloLogin": "Autenticazione",
        "TitoloRegistrazione": "Registrazione",
        "TitoloRecuperoPassword": "Recupero password",
        "TitoloResetPassword": "Reset password",
        "TitoloConfermaRegistrazione": "Conferma registrazione",

        "CampoNome": "Nome",
        "CampoCognome": "Cognome",
        "CampoEmail": "Email",
        "CampoSocieta": "Società",
        "CampoPassword": "Password",
        "CampoConfermaPassword": "Conferma password",

        "CampoObbligatorio": "Campo obbligatorio",

        "BottoneNonHaiAccount": "Non hai un account?",
        "BottoneRegistrati": "Registrati",
        "BottonePasswordDimenticata": "Password dimenticata?",

        "BottoneHaiAccount": "Hai già un account?",
        "BottoneAccedi": "Accedi",

        "BottoneModificaPassword": "Modifica password",


        "User not found": "L'utente non esiste",
        "User is disabled": "Devi abilitare l'account prima di poter accedere",
        "User already registered": "Utente già registrato",

        "TitoloPopupConfermaRegistrazione": "Registrazione avvenuta con successo!",
        "TestoPopupConfermaRegistrazione": "Controlla la tua mail per proseguire con l’attivazione dell’account"
    },

    "Admin": {
        "Salvataggio": {
            "NuovoElemento": "Elemento creato correttamente",
            "ModificaElemento": "Elemento modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio dell'elemento",

            "DipendenteAssociato": "Dipendente associato correttamente",
            "EliminaDipendente": "Sei sicuro di voler eliminare il dipendente?",
            "Conferma": "Conferma",
            
            "MessaggioModificheInSospeso": "Hai delle modifiche in sospeso, devi prima salvare per proseguire!",
            "AnnullaModifiche": "Annulla modifiche",
            "SalvaModifiche": "Salva modifiche",
            "Annulla": "Annulla",

            "ModificheInSospeso": "Hai delle modifiche in sospeso",
            "ModificheInSospesoTesto": "Vuoi salvare le modifiche prima di creare un nuovo elemento?",
        },

        "Form": {
            "CampoObbligatorio": "Campo obbligatorio",
        },

        "Dropzone": {
            "Testo": "Trascina qui il file o clicca per selezionarlo (max 10 MB)",
            "ErroreDimensioni": "Attenzione, il file supera 10 MB",
        },

        "Tabelle": {
            "Utenti": {
                "Address": "Indirizzo",
                "City": "Città",
                "Company": "Azienda",
                "Country": "Paese",
                "Email": "Email",
                "Enabled": "Abilitato",
                "Group": "Gruppo",
                "ID": "ID",
                "IsEmployee": "Dipendente",
                "IsSubscribed": "Abbonato",
                "Language": "Lingua",
                "LastLogin": "Ultimo accesso",
                "Name": "Nome",
                "Phone": "Telefono",
                "Province": "Provincia",
                "Sdi": "Sdi",
                "Surname": "Cognome",
                "TaxCode": "Codice fiscale",
                "URLImage": "Immagine",
                "VatCode": "Partita IVA",
                "ZIP": "CAP"
            },
            "Corsi": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Programma",
                "Date": "Data",
                "URLLive": "URL Lezione Live",
                "PreviewVideoURL": "URL Lezione Preview",
                "ShortDescription": "Descrizione breve",
                "Teacher": "Docente",
                "Language": "Lingua",
                "RegularPrice": "Prezzo",
                "DiscountPrice": "Prezzo scontato",
                "ImageURL": "Immagine",
            },
            "Live": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Programma",
                "Date": "Data",
                "URLLive": "URL Lezione Live",
                "PreviewVideoURL": "URL Lezione Preview",
                "ShortDescription": "Descrizione breve",
                "Teacher": "Docente",
                "Language": "Lingua",
                "RegularPrice": "Prezzo",
                "DiscountPrice": "Prezzo scontato",
                "ImageURL": "Immagine",
            },
            "DomandeLive": {
                "ID": "ID",
                "CreatedAt": "Data e ora",
                "UserID": "ID Utente",
                "Name": "Nome",
                "Surname": "Cognome",
                "Email": "Email",
                "Company": "Azienda",
                "Message": "Domanda",
            },
            "Quiz": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "HasFile": "File",
                "ThreesoldScore": "Punteggio minimo",
            },
            "Video": {
                "ID": "ID",
                "Title": "Titolo",
                "ShortDescription": "Descrizione breve",
                "Description": "Programma",
                "URLVideo": "URL Lezione",
                "SmallThumb": "Miniatura lezione",
            },
            "Risorse": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "URI": "URI",
            },
            "Coupon": {
                "ID": "ID",
                "Title": "Codice sconto",
                "Description": "Descrizione",
                "Value": "Importo",
                "CreatedAt": "Data creazione",
                "Type": "Tipo",
            },
            "Abbonamenti": {
                "ID": "ID",
                "Title": "Titolo",
                "ShortDescription": "Descrizione breve",
                "Description": "Descrizione",
                "Duration": "Durata",
                "EmployeesNumber": "Numero dipendenti",
                "RegularPrice": "Prezzo",
                "DiscountPrice": "Prezzo scontato",
                "Purchasable": "Acquistabile",
                "Type": "Tipo",
            },
            "Ordini": {
                "ID": "ID",
                "CreatedAt": "Data creazione",
                "UpdatedAt": "Ultima modifica",
                "Date": "Data",
                "Note": "Note",
                "Subtotal": "Subtotale",
                "Total": "Totale",
                "OrderRows": "Righe ordine",
                "Coupons": "Coupons",
                "OrderStatus": "Stato ordine",
                "UserID": "ID utente",
                "UserName": "Nome utente",
                "UserSurname": "Cognome utente",

                "InvoicingData_Name": "Nome",
                "InvoicingData_Surname": "Cognome",
                "InvoicingData_Email": "Email",
                "InvoicingData_Company": "Azienda",
                "InvoicingData_Country": "Paese",
                "InvoicingData_Address": "Indirizzo",
                "InvoicingData_ZIPCode": "CAP",
                "InvoicingData_City": "Città",
                "InvoicingData_Province": "Provincia",
                "InvoicingData_TaxIdentifier": "Partita IVA",
                "InvoicingData_PEC_SDI": "PEC/SDI",
            }
        },

        "Dashboard": {
            "CollegamentoUtenti": "Utenti",
            "CollegamentoCorsi": "Corsi",
            "CollegamentoRisorse": "Materiale didattico",
            "CollegamentoQuiz": "Quiz",
            "CollegamentoCoupons": "Coupons",
            "CollegamentoVideo": "Lezioni",
            "CollegamentoLive": "Live",
            "CollegamentoOrdini": "Ordini",
            "CollegamentoAbbonamenti": "Abbonamenti",
        },

        "Abbonamenti": {
            "Titolo": "Abbonamenti",
            "NuovoAbbonamento": "Nuovo abbonamento",
            "ModificaAbbonamento": "Modifica abbonamento",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Titolo",
            "CampoDescrizione": "Descrizione",
            "CampoDescrizioneBreve": "Descrizione breve",
            "CampoDurata": "Durata (in giorni)",
            "CampoNumeroDipendenti": "Numero dipendenti",
            "CampoPrezzo": "Prezzo",
            "CampoPrezzoScontato": "Prezzo scontato",
            "CampoTipo": "Tipo",
        },

        "Ordini": {
            "Titolo": "Ordini",
            "NuovoOrdine": "Nuovo ordine",
            "ModificaOrdine": "Modifica ordine",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",
        },

        "Utenti": {
            "Titolo": "Utenti",
            "NuovoUtente": "Nuovo utente",
            "ModificaUtente": "Modifica utente",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "ResetPassword": "Reset password",
            "MessaggioResetPassword": "Sei sicuro di voler proseguire? Verrà inviata una mail all'indirizzo dell'utente con la possibilità di impostare una nuova password.",
            "Conferma": "Conferma",
            "Annulla": "Annulla",
            "ErroreResetPassword": "Errore durante il reset della password",
            "MessaggioResetPasswordInviata": "Email per il reset della password inviata correttamente",

            "CampoNome": "Nome",
            "CampoCognome": "Cognome",
            "CampoAzienda": "Azienda",
            "CampoEmail": "Email",
            "CampoTelefono": "Telefono",

            "NascondiNomeCognome": "Nascondi nome e cognome",
            "TooltipNascondiNomeCognome": "Attiva se vuoi nascondere il nome e cognome dalla visualizzazione pubblica, utile ad esempio per gli utenti 'Ditta'",

            "CampoEmailProprietario": "Email proprietario",
            "CampoTipoAbbonamento": "Tipo abbonamento",
            "CampoDataScadenza": "Data scadenza",
            "CampoNumeroDipendenti": "Numero dipendenti",
            "ModaleDipendenti": "Dipendenti",
            "NessunDipendente": "Nessun dipendente",

            "CouponAssociati": "Coupon associati",
            "AggiungiCoupon": "Aggiungi coupon",
            "NessunCoupon": "Nessun coupon collegato all'utente",

            "CorrettoSalvataggio": "Utente salvato correttamente",
            "CorrettaModifica": "Utente modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio dell'utente",
        },
        "Risorse": {
            "Titolo": "Materiale didattico",
            "NuovaRisorsa": "Nuovo materiale",
            "ModificaRisorsa": "Modifica materiale",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoNome": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoFile": "File collegato",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato al materiale didattico",

            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezioni",
            "NessunVideo": "Nessuna lezione collegata al materiale didattico",

            "CorrettoSalvataggio": "Materiale salvato correttamente",
            "CorrettaModifica": "Materiale modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del materiale didattico",
        },
        "Quiz": {
            "Titolo": "Quiz",
            "NuovoQuiz": "Nuovo quiz",
            "ModificaQuiz": "Modifica quiz",
            
            "CampoNome": "Nome",
            "CampoDescrizione": "Descrizione (uso interno)",
            "CampoFile": "Seleziona file",
            "CampoPunteggioMinimo": "Punteggio minimo",

            "AnteprimaQuiz": "Anteprima quiz",
            "NessunQuizCaricato": "Nessun quiz caricato",

            "PlaceholderPunteggioMassimo": "Punteggio massimo",

            "CampoRispostaCorretta": "Risposta corretta",
            "CampoRispostaErrata": "Risposta errata",
            "CampoRispostaVuota": "Risposta vuota",

            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezione",
            "NessunVideo": "Nessuna lezione collegata al quiz",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato al quiz",

            "LiveAssociate": "Live associate",
            "AggiungiLive": "Aggiungi live",
            "NessunaLive": "Nessuna live collegata al quiz",

            "CorrettoSalvataggio": "Quiz salvato correttamente",
            "CorrettaModifica": "Quiz modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del quiz",
        },
        "Video": {
            "Titolo": "Lezioni",
            "NuovoVideo": "Nuova lezione",
            "ModificaVideo": "Modifica lezione",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Titolo",
            "CampoDescrizione": "Programma",
            "CampoDescrizioneBreve": "Descrizione breve",
            "CampoURL": "URL della lezione",
            "URLInseritoNonValido": "URL inserito non valido",


            "CampoQuiz": "Quiz associato",
            "CampoAutore": "Docente",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato alla lezione",

            "QuizAssociati": "Quiz associato",
            "AggiungiQuiz": "Aggiungi quiz",
            "NessunQuiz": "Nessun quiz collegato alla lezione",

            "RisorseAssociati": "Materiali didattici associati",
            "AggiungiRisorse": "Aggiungi materiale didattico",
            "NessunaRisorsa": "Nessun materiale collegato alla lezione",

            "Autore": "Docente",
            "DataCreazione": "Data creazione",
            "DataModifica": "Ultima modifica",

            "CorrettoSalvataggio": "Lezione salvata correttamente",
            "CorrettaModifica": "Lezione modificata correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio della lezione",
        },
        "Corsi": {
            "Titolo": "Corsi",
            "NuovoCorso": "Nuovo corso",
            "ModificaCorso": "Modifica corso",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Nome",
            "CampoDescrizione": "Programma",
            "CampoDescrizioneBreve": "Descrizione breve (max 100 caratteri)",
            "CampoData": "Data",
            "CampoURLLive": "URL Lezione Live",
            "CampoPreview": "URL Lezione Preview",
            "CampoDocente": "Docente",
            "CampoLingua": "Lingua",
            "CampoPrezzo": "Prezzo",
            "CampoPrezzoScontato": "Prezzo scontato",
            "CampoImmagine": "Immagine",
            "CampoDurata": "Durata (max 10 caratteri)",
            "CliccaPerCaricareImmagine": "Clicca per caricare l'immagine (max 10 MB)",
            "RimuoviImmagine": "Rimuovi immagine",

            "LiveAssociata": "Live associata",
            "GeneraNuovaLive": "Genera nuova live",


            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezione",
            "NessunVideo": "Nessuna lezione collegata al corso",

            "RisorseAssociate": "Materiali didattici associati",
            "AggiungiRisorse": "Aggiungi materiale didattico",
            "NessunaRisorsa": "Nessun materiale collegato al corso",

            "QuizAssociato": "Quiz associato",

            "CorrettoSalvataggio": "Corso salvato correttamente",
            "CorrettaModifica": "Corso modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del corso",

            "ImmagineSuperaDimensioni": "Attenzione, l'immagine supera 10 MB",
        },
        "Live": {
            "Titolo": "Live",
            "NuovaLive": "Nuova live",
            "ModificaLive": "Modifica live",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Nome",
            "CampoDescrizione": "Programma",
            "CampoDescrizioneBreve": "Descrizione breve (max 100 caratteri)",
            "CampoData": "Data",
            "CampoURL": "URL Lezione Live",
            "CampoPreview": "URL Lezione Preview",
            "CampoDocente": "Docente",
            "CampoLingua": "Lingua",
            "CampoPrezzo": "Prezzo",
            "CampoPrezzoScontato": "Prezzo scontato",
            "CampoImmagine": "Immagine",
            "CampoDurata": "Durata (max 10 caratteri)",
            "CliccaPerCaricareImmagine": "Clicca per caricare l'immagine (max 10 MB)",
            "RimuoviImmagine": "Rimuovi immagine",
            "GestioneChat": "Gestione chat",

            "RisorseAssociate": "Materiali didattici associati",
            "AggiungiRisorse": "Aggiungi materiale didattico",
            "NessunaRisorsa": "Nessun materiale collegato al corso",

            "QuizAssociato": "Quiz associato",
            "CorsoAssociato": "Corso associato",

            "GeneraNuovoCorso": "Genera nuovo corso",

            "CorrettoSalvataggio": "Lezione live salvata correttamente",
            "CorrettaModifica": "Lezione live modificata correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio della lezione live",

            "ImmagineSuperaDimensioni": "Attenzione, l'immagine supera 10 MB",

            "BottoneVaiAlleDomande": "Vai alle domande",
            "BottoneAggiornaDomande": "Aggiorna domande",

            "ChatAbilitata": "Chat abilitata",
            "ChatDisabilitata": "Chat disabilitata",

            "TestoPaginaDomande": "Domande ricevute",
        },
        "Coupons": {
            "Titolo": "Coupons",
            "NuovoCoupon": "Nuovo coupon",
            "ModificaCoupon": "Modifica coupon",
            "BottoneTornaIndietro": "Torna indietro",

            "CampoNome": "Codice sconto",
            "CampoDescrizione": "Descrizione (uso interno)",
            "CampoImporto": "Importo del codice promozionale",
            "BottoneGenera": "Genera codice casuale",
            "TipologiaPercentuale": "Percentuale",
            "TipologiaFisso": "Fisso",
            "UtentiAssociati": "Utenti associati",
            "AggiungiUtenti": "Aggiungi utenti",
            "EmailAssociate": "Email associate",
            "AggiungiEmail": "Aggiungi email",

            "NessunDato": "Coupon valido per tutti gli utenti",
            "NessunNuovoUtente": "Nessun nuovo utente da associare",
            "Autore": "Autore",
            "DataCreazione": "Data creazione",
            "DataModifica": "Ultima modifica",

            "BottoneSalva": "Salva",
            "BottoneAnnulla": "Annulla",
            "BottoneSalvaChiudi": "Salva e chiudi",

            "PlaceholderAutore": "Admin",
            "PlaceholderData": "gg/mm/aa",

            "CorrettoSalvataggio": "Coupon salvato correttamente",
            "CorrettaModifica": "Coupon modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del coupon",
        }
    },
    "Autenticazione": {
        "Login": {
            "Titolo": "Accedi",
        },
        "Registrazione": {
            "Titolo": "Registrati",
        },
        "RecuperoPassword": {
            "Titolo": "Recupero password",
        },
        "ResetPassword": {
            "Titolo": "Reset password",
        },
    }
}