import { useEffect, useState } from "react";
import Profile from "../Profile";
import { TextDictionary } from "../../utils/TextDictionary";
import { useLoaderData, useNavigate } from "react-router-dom";
import { CertificationModal } from "../../components/CertificationModal";
import DefaultImg from '../../assets/images/corso-teaser-demo.png';
import { PrimaryButton } from "../../components/PrimaryButton";

const UserCourses = () => {
    const navigate = useNavigate();

    const myProducts = useLoaderData() as any;
    const [certificationData, setCertificationData] = useState<any | null>(null);
    const [showCertificationModal, setShowCertificationModal] = useState(false);
    const [courseID, setCourseID] = useState<number>(-1);


    return (
        <div className="w-full flex flex-col">
            {
                showCertificationModal && certificationData && (
                    <CertificationModal
                        data={{ // Passo i dati per il certificato
                            Name: certificationData?.Name,
                            Surname: certificationData?.Surname,
                            CourseName: certificationData?.CourseName,
                            ObtainingYear: certificationData?.ObtainingYear
                        }}
                        courseId={courseID} // Passo l'ID del corso per poterlo utilizzare nel redirect alla pagina del corso
                        onClose={() => setShowCertificationModal(false)} // Passo la funzione per chiudere il modal
                    />
                )
            }

            { /* creo un header con il titolo della pagina centrato */}
            <div className="flex flex-col justify-center items-center w-full py-4 border-y border-black border-opacity-10 gap-2 md:border-t-0 border-x md:border-x-0">
                <h1 className="text-black text-lg md:text-2xl font-medium">{TextDictionary.CorsiUtente.Titolo}</h1>
                <span className="text-black text-opacity-80 text-sm md:text-md">{TextDictionary.CorsiUtente.Sottotitolo}</span>
            </div>

            { /* creo una lista di corsi stampando il titolo */}
            <div className="flex flex-col gap-4 p-4 border-x md:border-x-0 border-b md:border-b-0">

                {myProducts.length === 0 && (
                    <div className="flex flex-col items-center justify-center gap-8 p-8">
                        <span className="text-black italic text-center">{TextDictionary.CorsiUtente.NoCorsi}</span>
                        <PrimaryButton text={TextDictionary.CorsiUtente.ScopriCorsi} onClick={() => navigate('/public/corsi')} />
                    </div>
                )}


                {myProducts.map((product: any) => (
                    // Solo desktop
                    <div key={product.ID} className="flex flex-col md:flex-row border border-black border-opacity-20 p-4 items-center">
                        {/* Div con larghezza 10% */}
                        <div className="flex-shrink-0 hidden md:flex" style={{ flexBasis: "10%" }}>
                            <img
                                src={product.ImageURL ? product.ImageURL : DefaultImg}
                                alt={product.Title}
                                className="h-20 w-full object-contain cursor-pointer"
                                onClick={() => {
                                    navigate(`/public/corsi/${product.ID}`);
                                }}
                            />
                        </div>

                        {/* Div con larghezza 60% */}
                        <div className="flex-shrink-0 w-full" style={{ flexBasis: "60%" }}>
                            <p className="text-sm md:text-lg font-medium break-words whitespace-normal px-0 md:px-8 mb-8 md:mb-0 hover:underline cursor-pointer" onClick={() => {
                                navigate(`/public/corsi/${product.ID}`);
                            }}>
                                {product.Title}
                            </p>
                        </div>


                        {/* Div con larghezza 10% */}
                        <div className="flex-shrink-0 hidden md:block" style={{ flexBasis: "15%" }}>
                            {/* riscrivo mostrando anche la percentuale sopra */}
                            <p className="text-sm text-black text-center pb-2">{product.CompletionPercentage?.toFixed(0)}%</p>
                            <div className="flex flex-row h-[3px] bg-gray-200 rounded-lg overflow-hidden">
                                <div
                                    className="bg-primary rounded-lg"
                                    style={{ width: `${product.CompletionPercentage}%` }}
                                />
                                <div
                                    className="bg-gray-200 rounded-lg"
                                    style={{ width: `${100 - product.CompletionPercentage}%` }}
                                />
                            </div>
                        </div>

                        {/* Div con larghezza 10% */}
                        <div className="flex-shrink-0 items-center justify-center hidden md:flex" style={{ flexBasis: "15%" }}>
                            {/* in base a ObtainedCertification, se è null non mostro nulla, se è true mostro un div con bg-customYellow, se è false mostro un div con bg grigio */}
                            {product.ObtainedCertification === null ? (
                                null
                            ) : product.ObtainedCertification ? (
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 187.09 191.99" className="fill-customYellow h-14 w-14 cursor-pointer" onClick={() => {
                                    setCertificationData({
                                        Name: product.CertificationData.Name,
                                        Surname: product.CertificationData.Surname,
                                        CourseName: product.CertificationData.CourseName,
                                        ObtainingYear: product.CertificationData.ObtainingYear
                                    });
                                    setCourseID(product.ID);
                                    setShowCertificationModal(true);
                                }}>
                                    <g>
                                        <g id="Livello_1">
                                            <g>
                                                <rect x="49.83" y="84.53" width="47.3" height="25.09" />
                                                <rect x="49.83" y="115.25" width="47.3" height="24.23" />
                                                <polygon points="49.83 52.52 49.83 77.61 97.14 77.61 97.14 52.52 133.63 52.52 133.63 133.39 133.63 139.47 137.26 139.47 137.26 115.25 137.26 109.62 137.26 84.53 137.26 77.61 137.26 55.05 137.26 52.52 49.83 52.52" />
                                                <polygon points="97.14 139.47 133.63 139.47 114.49 118.96 97.14 139.47" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 187.09 191.99" className="fill-gray-200 h-14 w-14">
                                    <g>
                                        <g id="Livello_1">
                                            <g>
                                                <rect x="49.83" y="84.53" width="47.3" height="25.09" />
                                                <rect x="49.83" y="115.25" width="47.3" height="24.23" />
                                                <polygon points="49.83 52.52 49.83 77.61 97.14 77.61 97.14 52.52 133.63 52.52 133.63 133.39 133.63 139.47 137.26 139.47 137.26 115.25 137.26 109.62 137.26 84.53 137.26 77.61 137.26 55.05 137.26 52.52 49.83 52.52" />
                                                <polygon points="97.14 139.47 133.63 139.47 114.49 118.96 97.14 139.47" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            )}
                        </div>
                        <div className="flex md:hidden justify-between items-center w-full">
                            <div className="flex flex-col w-1/2">
                                <p className="text-sm text-black text-center pb-2">{product.CompletionPercentage?.toFixed(0)}%</p>
                                <div className="flex flex-row h-[3px] bg-gray-200 rounded-lg overflow-hidden">
                                    <div
                                        className="bg-primary rounded-lg"
                                        style={{ width: `${product.CompletionPercentage}%` }}
                                    />
                                    <div
                                        className="bg-gray-200 rounded-lg"
                                        style={{ width: `${100 - product.CompletionPercentage}%` }}
                                    />
                                </div>
                            </div>
                            {product.ObtainedCertification === null ? (
                                null
                            ) : product.ObtainedCertification ? (
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 187.09 191.99" className="fill-customYellow h-14 w-1/2 lg:w-14 cursor-pointer" onClick={() => {
                                    setCertificationData({
                                        Name: product.CertificationData.Name,
                                        Surname: product.CertificationData.Surname,
                                        CourseName: product.CertificationData.CourseName,
                                        ObtainingYear: product.CertificationData.ObtainingYear
                                    });
                                    setCourseID(product.ID);
                                    setShowCertificationModal(true);
                                }}>
                                    <g>
                                        <g id="Livello_1">
                                            <g>
                                                <rect x="49.83" y="84.53" width="47.3" height="25.09" />
                                                <rect x="49.83" y="115.25" width="47.3" height="24.23" />
                                                <polygon points="49.83 52.52 49.83 77.61 97.14 77.61 97.14 52.52 133.63 52.52 133.63 133.39 133.63 139.47 137.26 139.47 137.26 115.25 137.26 109.62 137.26 84.53 137.26 77.61 137.26 55.05 137.26 52.52 49.83 52.52" />
                                                <polygon points="97.14 139.47 133.63 139.47 114.49 118.96 97.14 139.47" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 187.09 191.99" className="fill-gray-200 h-14 w-1/2 lg:w-14">
                                    <g>
                                        <g id="Livello_1">
                                            <g>
                                                <rect x="49.83" y="84.53" width="47.3" height="25.09" />
                                                <rect x="49.83" y="115.25" width="47.3" height="24.23" />
                                                <polygon points="49.83 52.52 49.83 77.61 97.14 77.61 97.14 52.52 133.63 52.52 133.63 133.39 133.63 139.47 137.26 139.47 137.26 115.25 137.26 109.62 137.26 84.53 137.26 77.61 137.26 55.05 137.26 52.52 49.83 52.52" />
                                                <polygon points="97.14 139.47 133.63 139.47 114.49 118.96 97.14 139.47" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserCourses;