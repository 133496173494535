import { useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { ProductCard } from "../components/ProductCard";
import { ApiConfig } from "../config/Configuration";
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import { Course } from "../models/course";
import Slider from "react-slick";
import { PrimaryButton } from "../components/PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";
import { FeauturedProducts } from "../components/FeauturedProducts";

const PublicProducts = () => {
    // const products = useLoaderData() as any;
    const data = useLoaderData() as { products: any, title: string };

    return (
        <PublicLayout>
            <div className="flex flex-col">
                <div className="flex flex-col md:flex-row justify-between items-center bg-primary w-full">
                    <div className="max-w-[1400px] flex flex-row w-full items-center mx-auto py-12 px-10">
                        <h1 className="text-white text-3xl md:text-6xl font-medium mb-4 bodoni">{data.title}</h1>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center w-full py-10 max-w-[1400px] mx-auto px-4">
                    {/* <div className="w-full mb-14">
                        <FeauturedProducts />
                    </div> */}

                    {data.products && data.products.length > 0 ? (
                        <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-12 w-full">
                            {
                                data.products.map((product: any) => (
                                    <ProductCard key={product.ElementId} element={product} />
                                ))
                            }
                        </div>) : (
                        <span className="text-xl font-medium mb-12 pt-10 text-black text-opacity-50">{TextDictionary.ListaCorsi.NessunRisultato}</span>
                    )}

                </div>
            </div>
        </PublicLayout>
    );
};

export default PublicProducts;