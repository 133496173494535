import { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { unstable_usePrompt, useLoaderData, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { TextDictionary } from "../../../utils/TextDictionary";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import { ApiConfig, AppConfig } from "../../../config/Configuration";
import Editor, { BtnBold, BtnItalic, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { Course } from "../../../models/course";
import { saveFunction } from "../../../core/saveFunction";
import { Controller, useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import { axiosAuthInstance } from "../../../auth/sso/auth.interceptor";
import CustomDropzone from "../../../components/CustomDropzone";
import { Live } from "../../../models/live";

const SingleLive = () => {
    const navigate = useNavigate();

    const { id } = useParams();
    const live = useLoaderData() as any | null;

    const [selectedQuiz, setSelectedQuiz] = useState<any | null>(null);
    const [selectedCourse, setSelectedCourse] = useState<any | null>(null);
    const [data, setData] = useState<any[]>([]); // Array di oggetti con value e label per le opzioni del select
    const [coursesData, setCoursesData] = useState<any[]>([]);

    const [imageSrc, setImageSrc] = useState<string | undefined>(live?.ImageURL || undefined);

    const { register, handleSubmit, setValue, getValues, watch, control, formState: { errors, isDirty, isSubmitting }, reset } = useForm<Live>({
        defaultValues: {
            PreviewVideoURL: live?.PreviewVideoURL || '',
            Title: live?.Title || '',
            Description: live?.Description || '',
            ShortDescription: live?.ShortDescription || '',
            Teacher: live?.Teacher || '',
            Date: live?.Date || '',
            Language: live?.Language || '',
            RegularPrice: live?.RegularPrice || 200,
            DiscountPrice: live?.DiscountPrice || null,
            ResourceIDs: live?.ResourceIDs || [],
            QuizID: null,
            CourseID: live?.CourseID || null,
            URLLiveSession: live?.URLLiveSession || '',
            ImageURL: live?.ImageURL || '',
            FileData: null,
            EmbeddedPreviewVideoURLHTML: live?.EmbeddedPreviewVideoURLHTML || '',
            Duration: live?.Duration || '',
            Featured: live?.Featured || false,
            ChatEnabled: live?.ChatEnabled || false
        }
    });
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);
    const onSubmit = (data: Live, saveAndClose?: boolean) => saveFunction(data, id, "lives", "Live", setIsSaving, navigate, saveAndClose); // Invoco la funzione di salvataggio 
    // passando i parametri necessari

    const options = {
        resources: {
            endpoint: "/resources",
            element: { live },
            dictionaryContext: "Risorse",
            columsToShow: ["Title", "Description"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "ResourceIDs",
            leftLabel: TextDictionary.Admin.Live.RisorseAssociate,
            rightLabel: TextDictionary.Admin.Live.AggiungiRisorse,
            noDataMessage: TextDictionary.Admin.Live.NessunaRisorsa,
            gridName: "resourcesGrid",
            control: control
        },
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname && !nextLocation.pathname.includes('/admin/courses/')
    });

    useEffect(() => {
        const fetchQuiz = async () => {
            const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/quizzes`);
            const data = response.data;

            const options = data.map((quiz: any) => {
                return {
                    value: quiz.ID,
                    label: quiz.Title
                };
            });

            setData(options);

            if (!live) return;

            const selected = options.find((quiz: any) => quiz.value === live.QuizID);
            setSelectedQuiz(selected);
        };

        const fetchCourses = async () => {
            const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/courses`);
            const data = response.data;

            const options = data.map((course: Course) => {
                return {
                    value: course.ID,
                    label: course.Title
                };
            });

            setCoursesData(options);

            if (!live) return;

            const selected = options.find((course: any) => course.value === live.CourseID);
            setSelectedCourse(selected);
        };

        fetchCourses();
        fetchQuiz();

        if (live) {
            const formattedDate = new Date(live.Date);
            const formattedDateString = `${formattedDate.getFullYear()}-${(formattedDate.getMonth() + 1).toString().padStart(2, '0')}-${formattedDate.getDate().toString().padStart(2, '0')}T${formattedDate.getHours().toString().padStart(2, '0')}:${formattedDate.getMinutes().toString().padStart(2, '0')}`;

            reset({
                PreviewVideoURL: live.PreviewVideoURL,
                Title: live.Title,
                Description: live.Description,
                ShortDescription: live.ShortDescription,
                Teacher: live.Teacher,
                Date: formattedDateString,
                Language: live.Language,
                RegularPrice: live.RegularPrice || 200,
                DiscountPrice: live.DiscountPrice || null,
                URLLiveSession: live.URLLiveSession || '',
                ResourceIDs: live.ResourceIDs,
                QuizID: null,
                CourseID: live.CourseID || null,
                ImageURL: live.ImageURL || '',
                FileData: null,
                EmbeddedPreviewVideoURLHTML: live.EmbeddedPreviewVideoURLHTML || '',
                Duration: live.Duration || '',
                Featured: live.Featured || false,
                ChatEnabled: live.ChatEnabled || false
            });

            setImageSrc(live.ImageURL || undefined);
            handleFeauturedIcon();
        }
    }, [live]);

    const handleChange = (selected: any) => {
        // Gestisco la casistica in cui l'utente rimuove la selezione
        setSelectedQuiz(selected);
        setValue("QuizID", +selected?.value || null);
    };

    const handleChangeCourse = (selected: any) => {
        // Gestisco la casistica in cui l'utente rimuove la selezione
        setSelectedCourse(selected);
        setValue("CourseID", +selected?.value || null);
    }

    const handleDiscard = () => {
        navigate('/admin/lives');
    }

    const handleRemoveImage = () => {
        setImageSrc(undefined);
        setValue("FileData", null);
        setValue("ImageURL", null);
    }

    const handleSave = async (saveAndClose?: boolean) => {
        try {
            await handleSubmit(() => onSubmit(getValues(), saveAndClose))();
            setToggleAlert(!toggleAlert);
        } catch (error: any) {
            // alert("Errore durante il salvataggio: \n " + error);
            const errorMessages = [];
            for (const key in error.response.data.errors) {
                errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
            }

            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: errorMessages.join('\n')
            });
        }
    }


    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = e.target?.result;
                setImageSrc(data as string);
                setValue("FileData", file);
            };

            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    const feauturedValue = watch('Featured') as boolean;
    useEffect(() => {
        handleFeauturedIcon();
    }, [feauturedValue]);

    const handleFeauturedIcon = () => {
        let icon = document.querySelector('.feautured-button') as HTMLElement;
        if (feauturedValue) {
            icon.style.backgroundColor = AppConfig.customYellow;
        } else {
            icon.style.backgroundColor = AppConfig.gray;
        }
    }

    // const chatValue = watch('ChatEnabled') as boolean;
    // useEffect(() => {
    //     handleChatIcon();
    // }, [chatValue]);

    // const handleChatIcon = () => {
    //     let icon = document.querySelector('.chat-button') as HTMLElement;
    //     if (chatValue) {
    //         icon.style.backgroundColor = AppConfig.primaryColor;
    //     } else {
    //         icon.style.backgroundColor = AppConfig.gray;
    //     }
    // }

    const handleFastCreation = async () => {
        // Se ho delle modifiche in sospeso, mostro un avviso di conferma all'utente
        if (isDirty) {
            const result = await Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ModificheInSospeso,
                text: TextDictionary.Admin.Salvataggio.ModificheInSospesoTesto,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: TextDictionary.Admin.Salvataggio.SalvaModifiche,
                cancelButtonText: TextDictionary.Admin.Salvataggio.AnnullaModifiche
            });

            if (result.isConfirmed) {
                await handleSave(false);
            } else if (result.isDismissed) {
                return;
            }
        }

        const newCourse = {
            Title: getValues().Title,
            Description: getValues().Description,
            ShortDescription: getValues().ShortDescription,
            Teacher: getValues().Teacher,
            Language: getValues().Language,
            Date: getValues().Date,
            RegularPrice: getValues().RegularPrice,
            DiscountPrice: getValues().DiscountPrice,
            ResourceIDs: getValues().ResourceIDs,
            VideoIDs: [],
            QuizID: getValues().QuizID,
            ImageURL: getValues().ImageURL,
            FileData: getValues().FileData,
            EmbeddedPreviewVideoURLHTML: getValues().EmbeddedPreviewVideoURLHTML,
            Duration: getValues().Duration,
            Featured: getValues().Featured,
            LiveID: live?.ID
        };

        try {
            const response = await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/courses`, newCourse);
            if (response.status === 201) {
                // Navigo alla pagina del corso appena creato
                navigate(`/admin/courses/${response.data.ID}`);
            }
        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: error.response.data.message
            });
        }
    }

    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Live.ModificaLive : TextDictionary.Admin.Live.NuovaLive}</h1>

                    <div className="flex flex-row w-full gap-8">
                        <div className="flex flex-col gap-4 w-full flex-1">
                            <div className="module-card flex flex-col w-full">
                                <div className="flex flex-row items-center justify-between gap-4">
                                    {/* Nome */}
                                    <div className="flex flex-col mb-4 w-full">
                                        <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoTitolo}*</label>
                                        <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                            {...register("Title", { required: TextDictionary.Admin.Form.CampoObbligatorio })}
                                            autoFocus
                                        />
                                        {errors.Title && <p role="alert" className="text-errorRed text-sm">{errors.Title.message}</p>}
                                    </div>
                                    {/* In Evidenza, mostro una stellina con sfondo customYellow se IsFeatured è true, altrimenti una stellina vuota */}
                                    <button
                                        onClick={() => {
                                            setValue('Featured', !getValues().Featured, { shouldDirty: true });
                                        }}
                                        className={`feautured-button text-white rounded-full h-8 aspect-square mt-6 flex justify-center items-center`}
                                        aria-label={feauturedValue ? 'Rimuovi dai corsi in evidenza' : 'Aggiungi ai corsi in evidenza'}
                                        // aggiungo un tooltip
                                        title={feauturedValue ? 'Rimuovi dai corsi in evidenza' : 'Aggiungi ai corsi in evidenza'}
                                    >
                                        ★
                                    </button>
                                </div>
                                {/* Descrizione breve */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoDescrizioneBreve}</label>
                                    <Controller
                                        name="ShortDescription"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <EditorProvider>
                                                <Editor
                                                    onChange={(e) => {
                                                        // Controllo se la lunghezza del testo è maggiore di 100 caratteri
                                                        if (e.target.value.length > 100)
                                                            return;
                                                        onChange(e.target.value)
                                                    }
                                                    }
                                                    value={value}
                                                    className="border border-black border-opacity-10 w-full resize-none px-2"
                                                    containerProps={{ style: { height: '200px', width: '100%', overflowY: 'auto' } }}
                                                    name="ShortDescription"
                                                >
                                                    <Toolbar>
                                                        <BtnBold />
                                                        <BtnItalic />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        )}
                                    />
                                </div>

                                {/* Descrizione */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoDescrizione}</label>
                                    <Controller
                                        name="Description"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <EditorProvider>
                                                <Editor
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    className="border border-black border-opacity-10 w-full resize-none px-2"
                                                    containerProps={{ style: { height: '200px', width: '100%', overflowY: 'auto' } }}
                                                    name="Description"
                                                >
                                                    <Toolbar>
                                                        <BtnBold />
                                                        <BtnItalic />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        )}
                                    />
                                </div>
                            </div>

                            {/* Data e Durata */}
                            <div className="module-card flex flex-row gap-8 w-full">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoData}*</label>
                                    <input aria-label="Data e ora" type="datetime-local"
                                        {...register("Date", { required: TextDictionary.Admin.Form.CampoObbligatorio })}
                                        className="border border-black border-opacity-10 px-2 py-1 custom-datetime-local" />
                                    {errors.Date && <p role="alert" className="text-errorRed text-sm">{errors.Date.message}</p>}
                                </div>

                                { /* riscrivo il campo della durata impostando un massimo di 10 caratteri */}
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoDurata}</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1" maxLength={10}
                                        {...register("Duration")}
                                    />
                                </div>
                            </div>

                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-8">{TextDictionary.Admin.Live.GestioneChat}</label>
                                <div className="flex flex-row items-center gap-8">
                                    {/* <button
                                        onClick={() => {
                                            setValue('ChatEnabled', !getValues().ChatEnabled, { shouldDirty: true });
                                        }}
                                        className={`chat-button rounded-full h-12 aspect-square flex justify-center items-center`}
                                    >
                                        <svg fill="white" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 60 60" xmlSpace="preserve">
                                            <g>
                                                <path d="M44.348,12.793H2.652C1.189,12.793,0,13.983,0,15.446v43.761l9.414-9.414h34.934c1.463,0,2.652-1.19,2.652-2.653V15.446
                                            C47,13.983,45.811,12.793,44.348,12.793z M11,22.793h12c0.553,0,1,0.448,1,1s-0.447,1-1,1H11c-0.553,0-1-0.448-1-1
                                            S10.447,22.793,11,22.793z M36,38.793H11c-0.553,0-1-0.448-1-1s0.447-1,1-1h25c0.553,0,1,0.448,1,1S36.553,38.793,36,38.793z
                                            M36,31.793H11c-0.553,0-1-0.448-1-1s0.447-1,1-1h25c0.553,0,1,0.448,1,1S36.553,31.793,36,31.793z"/>
                                                <path d="M57.348,0.793H12.652C11.189,0.793,10,1.983,10,3.446v7.347h34.348c2.565,0,4.652,2.087,4.652,4.653v25.347h1.586H60V3.446
                                            C60,1.983,58.811,0.793,57.348,0.793z"/>
                                            </g>
                                        </svg>
                                    </button> */}



                                    <label className="inline-flex items-center cursor-pointer relative">
                                        <input type="checkbox" className="peer sr-only" {...register("ChatEnabled")} />
                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                                            <span className="text-md ml-6">
                                                {
                                                    getValues().ChatEnabled ? (
                                                        TextDictionary.Admin.Live.ChatAbilitata
                                                    ) : (
                                                        TextDictionary.Admin.Live.ChatDisabilitata
                                                    )
                                                }
                                            </span>
                                    </label>

                                    {
                                        id && (
                                            <button className="bg-primary text-white font-medium py-2 px-4 rounded flex items-center gap-4" onClick={() => {
                                                // navigate(`/admin/lives/${id}/questions`);
                                                // Navigo alla pagina delle domande con target _blank
                                                window.open(`/admin/lives/${id}/questions`, '_blank');
                                            }}>
                                                {TextDictionary.Admin.Live.BottoneVaiAlleDomande}
                                                <svg width="12px" height="12px" viewBox="0 0 12 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.369 7.38378L10.369 7.53378H10.519L11.0774 7.53378L11.2274 7.53378V7.38378V0.922726L11.2274 0.772726L11.0774 0.772726L4.61634 0.772725L4.46634 0.772725L4.46634 0.922725L4.46634 1.48109L4.46634 1.63109L4.61634 1.63109L9.75806 1.63109L0.521969 10.8672L0.415903 10.9732L0.521969 11.0793L0.920799 11.4781L1.02687 11.5842L1.13293 11.4781L10.369 2.24205V7.38378Z" fill="white" stroke="white" strokeWidth="0.3" />
                                                </svg>
                                            </button>
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 w-full flex-1">
                            {/* Corso associato */}
                            <div className="module-card flex flex-col w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CorsoAssociato}</label>
                                <Controller
                                    name="CourseID"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Select
                                            className='mb-4'
                                            options={coursesData}
                                            value={selectedCourse}
                                            isSearchable
                                            isClearable // Permette di svuotare il campo di ricerca
                                            onChange={(selected: any) => {
                                                setSelectedCourse(selected);
                                                setValue("CourseID", +selected?.value || null);
                                                onChange(selected?.value || null);
                                            }}
                                            closeMenuOnSelect={true}  // Mantiene il menu aperto dopo la selezione
                                            hideSelectedOptions={false}  // Mantiene le opzioni selezionate visibili nella lista
                                            placeholder="Cerca corso..."
                                        />
                                    )}
                                />
                                <button className="bg-primary text-white font-medium py-2 px-4 rounded" onClick={handleFastCreation}
                                    style={id && !getValues().CourseID ? { opacity: 1, cursor: 'pointer' } : { opacity: 0.5, cursor: 'not-allowed' }}
                                    disabled={id && !getValues().CourseID ? false : true}
                                >
                                    {TextDictionary.Admin.Live.GeneraNuovoCorso}
                                </button>
                            </div>

                            {/* Immagine */}
                            <div className="module-card flex flex-col w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoImmagine}</label>
                                <div className="relative">
                                    {/* se non è presente FileName, mostro il dropzone per caricare il file */}
                                    {imageSrc === undefined && (
                                        <CustomDropzone
                                            onDrop={onDrop}
                                            accept={{
                                                accept: 'image/*',
                                            }}
                                            maxFiles={1}
                                        />
                                    ) || (
                                            <div className="flex flex-col gap-4 mb-4 w-full border">
                                                <div className="flex flex-row items-center justify-center gap-4">
                                                    <img src={imageSrc} alt="Immagine corso" className="w-full h-full object-contain" />
                                                    <button
                                                        onClick={handleRemoveImage}
                                                        className="absolute top-0 right-0 bg-errorRed text-white rounded-full h-8 w-8 flex justify-center items-center"
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>

                            {/* Quiz associato */}
                            {/* <div className="module-card flex flex-col w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.QuizAssociato}</label>
                                <Controller
                                    name="QuizID"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            className='mb-4'
                                            options={data}
                                            value={selectedQuiz}
                                            isSearchable
                                            isClearable // Permette di svuotare il campo di ricerca
                                            onChange={handleChange}
                                            closeMenuOnSelect={true}  // Mantiene il menu aperto dopo la selezione
                                            hideSelectedOptions={false}  // Mantiene le opzioni selezionate visibili nella lista
                                            placeholder="Cerca quiz..."
                                        />
                                    )}
                                />
                            </div> */}

                            <div className="module-card flex flex-col w-full">
                                {/* URL Video Anteprima */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoURL}</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register("URLLiveSession")}
                                    />
                                </div>
                            </div>

                            <div className="module-card flex flex-col w-full">
                                {/* URL Video Anteprima */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoPreview}</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register("PreviewVideoURL")}
                                    />
                                </div>

                                {
                                    live?.EmbeddedPreviewVideoURLHTML && (
                                        // Creo un div che abbia come innerHTML il valore dell'attributo EmbeddedHTML
                                        <div
                                            dangerouslySetInnerHTML={{ __html: live?.EmbeddedPreviewVideoURLHTML || '' }}
                                            className="course-iframe-container mt-4 w-full"
                                        />
                                    )
                                }
                            </div>

                            {/* Docente */}
                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoDocente}</label>
                                <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                    {...register("Teacher")}
                                />
                            </div>

                            {/* Lingua */}
                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoLingua}</label>
                                <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                    {...register("Language")}
                                />
                            </div>

                            {/* Prezzo */}
                            <div className="module-card flex flex-row mb-4 gap-8 w-full">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoPrezzo}*</label>
                                    <div className="flex flex-row gap-2 items-center">
                                        <input type="number" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                            {...register("RegularPrice", { required: TextDictionary.Admin.Form.CampoObbligatorio, valueAsNumber: true })}
                                        />
                                        <span className="font-semibold text-md">€</span>
                                    </div>
                                    {errors.RegularPrice && <p role="alert" className="text-errorRed text-sm">{errors.RegularPrice.message}</p>}
                                </div>
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Live.CampoPrezzoScontato}</label>
                                    <div className="flex flex-row gap-2 items-center">
                                        <input type="number" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                            {...register("DiscountPrice", { valueAsNumber: true })}
                                        />
                                        <span className="font-semibold text-md">€</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Associazione con le risorse */}
                    <RelationHandlerComponent
                        options={options.resources}
                        setValue={setValue}
                    />
                </div>

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }}
                    onSaveAndClose={() => {
                        handleSave(true);
                    }} onDiscard={() => {
                        handleDiscard();
                    }}
                    isSubmitting={isSubmitting}
                />
            </div>


        </AdminLayout>
    );
};

export default SingleLive;