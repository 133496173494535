import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { TextDictionary } from "../utils/TextDictionary";
import Swal from "sweetalert2";

function CustomDropzone({ onDrop, accept, maxFiles }) {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles || undefined,
    onDrop: (files, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        Swal.fire({
          title: TextDictionary.Admin.Dropzone.ErroreDimensioni
        });
      } else {
        setAcceptedFiles(acceptedFiles.concat(files));
        onDrop(acceptedFiles.concat(files));
      }
    },
    accept: accept,
    maxSize: 10485760,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path} className="flex flex-row justify-between first:mt-8 mt-2 text-sm">
      <span>{file.path}</span>
      <button className="text-errorRed" onClick={() => {
        const newFiles = acceptedFiles.filter((f) => f.path !== file.path);
        setAcceptedFiles(newFiles);
        onDrop(newFiles);
      }}>
        x
      </button>
    </li>
  ));

  return (
    <div className="container border border-dotted border-black w-full px-4 py-4">
      <div {...getRootProps({ className: "dropzone" })} className="cursor-pointer">
        <input {...getInputProps()} />
        <img src="https://img.icons8.com/ios/452/upload-to-cloud.png" alt="upload" className="mx-auto w-10 h-10 mb-4" />
        <p className="text-center text-sm">
          {TextDictionary.Admin.Dropzone.Testo}
        </p>
      </div>
      <aside className="text-center">
        <ul className="list-none">
          {files}
        </ul>
      </aside>
    </div>
  );
}

export default CustomDropzone;