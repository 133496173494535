import { useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { Outlet, NavLink } from "react-router-dom";
import { TextDictionary } from "../utils/TextDictionary";
import EmptyAvatar from "../assets/images/empty-avatar.png";
import { UserService } from "../services/user.service";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig } from "../config/Configuration";
import Swal from "sweetalert2";
import { AuthUser } from "../auth/interfaces/user.interface";

const Profile = () => {
    // const [imageSrc, setImageSrc] = useState(UserService.getInstance().user?.URLImage || EmptyAvatar);

    const [imageSrc, setImageSrc] = useState(EmptyAvatar);
    const [user, setUser] = useState(UserService.getInstance().user);

    useEffect(() => {
        // async function handleUser() {
        //     await UserService.getInstance().getUserData(); // Ottengo i dati dell'utente
        // }

        // handleUser().then(() => {
        //     // Salvo i permessi dell'utente dentro il servizio UserService, usando l'enum IDPPermission 
        //     const user = UserService.getInstance().user;
        //     if (user) {
        //         console.log('user', user);
        //         setUser(user);
        //         setImageSrc(user.URLImage || EmptyAvatar);
        //     }
        // }).catch((error) => {
        //     console.error(error);
        // });


        const loadUserData = async () => {
            const userService = UserService.getInstance();
            const isUserAvailable = await userService.getUserData();
            if (isUserAvailable && userService.user) {
                setImageSrc(userService.user?.URLImage || EmptyAvatar);
                setUser(userService.user);
            }
        };

        loadUserData();
    }, []);

    return (
        <PublicLayout>
            <div className="flex max-w-[1400px] h-full justify-center items-center mx-auto my-0 md:my-10">
                <div className="flex flex-col md:flex-row h-full w-full border border-black border-opacity-10">
                    <aside className="flex w-full md:w-60 h-full flex-col overflow-y-hidden border-r border-black border-opacity-10">
                        <img
                            src={imageSrc}
                            alt="Empty avatar illustration"
                            className="w-[120px] h-[120px] md:w-[150px] md:h-[150px] mx-auto cursor-pointer rounded-full border border-black border-opacity-20 mb-4 mt-8 md:mt-4 object-contain"
                            onClick={() => {
                                const input = document.createElement('input');
                                input.type = 'file';
                                input.accept = 'image/*';
                                input.click();

                                input.addEventListener('change', async (e) => {
                                    const file = (e.target as HTMLInputElement).files?.[0];
                                    if (file) {
                                        const newImageSrc = URL.createObjectURL(file);
                                        setImageSrc(newImageSrc);

                                        // Creo il formData con il file e il json con i dati dell'utente
                                        const data = new FormData();
                                        data.append('file', file);
                                        const userData = {
                                            ID: UserService.getInstance().user?.ID,
                                            Name: UserService.getInstance().user?.Name,
                                            Surname: UserService.getInstance().user?.Surname,
                                            Email: UserService.getInstance().user?.Email,
                                        }
                                        data.append('json', JSON.stringify(userData));

                                        try {
                                            const response = await axiosAuthInstance.put(ApiConfig.ROOT_URL + '/auth/user', data);
                                            if (response.status >= 200 && response.status < 300)
                                                UserService.getInstance().user = response.data;

                                        } catch (error) {
                                            Swal.fire({
                                                title: 'Errore',
                                                text: 'Errore durante il caricamento dell\'immagine',
                                                icon: 'error',
                                                confirmButtonText: 'Chiudi'
                                            });

                                            setImageSrc(UserService.getInstance().user?.URLImage || EmptyAvatar);
                                        }
                                    }
                                });
                            }}
                        />
                        
                        { !user?.HidePersonalData && (
                        <span className="text-black text-md font-medium text-center py-4">{user?.Name ?? 'Nome'} {user?.Surname ?? 'Cognome'}</span>
                        )}
                        <div className="md:border-t border-black border-opacity-10 mb-2"></div>
                        <ul className="flex flex-col py-4 md:py-0 list-none">
                            <li>
                                <NavLink
                                    to="my-profile"
                                    className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black text-sm md:text-md"
                                    style={({ isActive, isPending }) => {
                                        return {
                                            textDecoration: isActive || isPending ? "underline" : "none",
                                        };
                                    }}
                                >
                                    {TextDictionary.Profilo.MioProfilo}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="courses"
                                    className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black text-sm md:text-md"
                                    style={({ isActive, isPending }) => {
                                        return {
                                            textDecoration: isActive || isPending ? "underline" : "none",
                                        };
                                    }}
                                >
                                    {TextDictionary.Profilo.MieiCorsi}
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink
                                    to="certificates"
                                    className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black text-sm md:text-md"
                                    style={({ isActive, isPending }) => {
                                        return {
                                            textDecoration: isActive || isPending ? "underline" : "none",
                                        };
                                    }}
                                >
                                    {TextDictionary.Profilo.Certificati}
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink
                                    to="subscriptions"
                                    className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black text-sm md:text-md"
                                    style={({ isActive, isPending }) => {
                                        return {
                                            textDecoration: isActive || isPending ? "underline" : "none",
                                        };
                                    }}
                                >
                                    {TextDictionary.Profilo.GestioneAbbonamenti}
                                </NavLink>
                            </li>
                        </ul>
                    </aside>
                    <main className="flex grow flex-1 w-full p-4 md:p-0">
                        {/* Qui verranno renderizzati i componenti figli */}
                        {
                            user ? <Outlet /> :
                                <div className="flex flex-col justify-center items-center w-full h-full">
                                    <span className="text-black text-lg font-medium">{TextDictionary.ProfiloUtente.Caricamento}</span>
                                </div>
                        }
                    </main>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Profile;
