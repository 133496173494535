import { useContext, useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import { useLoaderData, useNavigate } from "react-router-dom";
import { CartContext } from "../core/cartProvider";
import { PrimaryButton } from "../components/PrimaryButton";
import { UserService } from "../services/user.service";

const PublicSubscriptions = () => {
    const navigate = useNavigate();

    const subscriptions = useLoaderData() as any;
    const { addToCart, isCourseAlreadyInCart } = useContext(CartContext);

    const [user, setUser] = useState(UserService.getInstance().user);

    useEffect(() => {
        const loadUserData = async () => {
            const userService = UserService.getInstance();
            const isUserAvailable = await userService.getUserData();
            if (isUserAvailable && userService.user) {
                setUser(userService.user);
            }
        };

        loadUserData();
    }, []);

    const getMonthsByDays = (days: number) => {
        const months = days / 30;
        return months.toFixed(0);
    }

    return (
        <PublicLayout>
            <div className="flex flex-col justify-center w-full py-20 max-w-[1400px] mx-auto h-full px-10">
                <h1 className="text-3xl lg:text-5xl font-semibold text-center text-primary mb-8 leading-[40px] lg:leading-[60px]">{TextDictionary.Abbonamenti.Titolo}</h1>
                <h2 className="text-md text-center text-black mb-8">{TextDictionary.Abbonamenti.Sottotitolo}</h2>
                <div className="flex flex-col lg:flex-row gap-8 items-stretch justify-center py-12">
                    {
                        subscriptions.map((subscription: any) => (
                            <div key={subscription.ID} className="flex flex-col gap-4 p-10 bg-cardBackground shadow-md rounded-2xl w-full lg:w-1/3 h-auto min-h-[400px]">
                                <div className="flex flex-col gap-4 mb-4 flex-grow">
                                    <span className="text-md text-black text-opacity-90 text-center italic h-10">{subscription.ShortDescription}</span>
                                    <p className="text-2xl font-semibold text-primary text-center">{subscription.Title}</p>
                                    <div className="flex flex-col  items-center justify-center mb-4">
                                        {subscription.DiscountPrice ? (
                                            <>
                                                <p className="text-2xl text-gray-500 line-through mb-2">{subscription.RegularPrice?.toFixed(2) ?? 0} €</p>
                                                <p className="text-3xl text-primary font-semibold flex items-center gap-4">
                                                    {subscription.DiscountPrice?.toFixed(2) ?? 0} €
                                                </p>
                                                <span className="text-sm text-gray-600 font-normal">al mese per {getMonthsByDays(subscription.Duration)} mesi</span>
                                                <span className="text-xs text-gray-600 font-normal">iva esclusa</span>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-3xl text-primary font-semibold flex items-center gap-4 mb-2">
                                                    {subscription.RegularPrice?.toFixed(2) ?? 0} €
                                                </p>
                                                <span className="text-sm text-gray-600 font-normal">al mese per {getMonthsByDays(subscription.Duration)} mesi</span>
                                                <span className="text-xs text-gray-600 font-normal">iva esclusa</span>
                                            </>
                                        )}
                                    </div>
                                    <span className="text-sm text-black text-opacity-90 pb-4 h-32">{subscription.Description}</span>
                                    <div className="features-container text-md text-black text-opacity-90 text-left leading-8 px-4"
                                        dangerouslySetInnerHTML={{ __html: subscription.Features, }} />
                                </div>
                                {/* <span className="text-sm text-black text-opacity-90">{subscription.Description}</span> */}


                                {/* 1. Non ho abbonamenti, mostro sempre il bottone con scritta acquista ora 
                                            2. Ho comprato un abbonamento, mostro bottone disabilitato con scritto "Già abbonato" in grigio
                                            3. Se sono dipendente, avrò un bottone disabilitato con scritto "Sei già abbonato" nella card 
                                            dell'abbonamento che possiedo dentro UserSubscriptionData. Nelle altre avrò una label in grigio con scritto
                                            "Non hai i permessi per acquistare questo abbonamento" */}

                                <div className="mt-auto w-full flex flex-col">
                                    {subscription.Access.CanPurchase && (
                                        <PrimaryButton text={TextDictionary.ListaCorsi.BottoneAcquista} onClick={() => {
                                            // Controllo se il corso è già nel carrello
                                            if (!isCourseAlreadyInCart(subscription)) { // Se il corso non è nel carrello, lo aggiungo
                                                addToCart(subscription)
                                            }
                                            navigate('/cart');
                                        }} />
                                    )}

                                    {user?.UserSubscriptionData?.find((us: any) => us.SubscriptionID === subscription.ID) && !subscription.Access.CanPurchase && (
                                        <PrimaryButton text={TextDictionary.ListaCorsi.SeiGiaAbbonato} disabled onClick={() => { }} />
                                    )}

                                    {user?.UserSubscriptionEmployeeData && user?.UserSubscriptionEmployeeData?.find((us: any) => us.SubscriptionID === subscription.ID) && !subscription.Access.CanPurchase && (
                                        <PrimaryButton text={TextDictionary.ListaCorsi.SeiGiaAbbonato} disabled onClick={() => { }} />
                                    )}

                                    {user?.UserSubscriptionEmployeeData && !user?.UserSubscriptionEmployeeData?.find((us: any) => us.SubscriptionID === subscription.ID) && !subscription.Access.CanPurchase && (
                                        <span className="text-sm text-gray-600 font-normal text-center">{TextDictionary.Abbonamenti.NonHaiPermessi}</span>
                                    )}
                                </div>




                            </div>
                        ))
                    }
                </div>

                {/* <span className="text-xl text-primary font-medium mb-8">Termini e condizioni</span>
                <span className="text-md text-black text-opacity-90">{TextDictionary.Abbonamenti.TerminiCondizioni}</span> */}
            </div>
        </PublicLayout>
    );
};

export default PublicSubscriptions;