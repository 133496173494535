

import { createContext, useState, useEffect } from 'react';
import { axiosAuthPublicInstance } from '../auth/sso/auth.interceptor';
import { ApiConfig } from '../config/Configuration';
import Swal from 'sweetalert2';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []);
  const [promoCode, setPromoCode] = useState(localStorage.getItem('promoCode') ? localStorage.getItem('promoCode') : '');

  const addToCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.ID === item.ID);

    if (isItemInCart) {
      // Non faccio nulla
    } else {
      setCartItems([...cartItems, { ...item }]);
    }
  };

  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.ID === item.ID);

    if (isItemInCart) {
      setCartItems(cartItems.filter((cartItem) => cartItem.ID !== item.ID));
    } else {
      // Non faccio nulla
    }
  };

  const isCourseAlreadyInCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.ID === item.ID);

    if (isItemInCart) {
      return true;
    } else {
      return false;
    }
  }

  const clearCart = () => {
    setCartItems([]);
    setPromoCode('');
  };

  const getCartTotal = async () => {
    // /api/orders/cart-total

    const payload = {
      Note: "Nota dell'ordine",
    };

    payload.OrderRows = cartItems.map((product) => {
      if (product.Type === 'Course') {
        return {
          CourseID: product.ID,
          Note: "Nota corso"
        };
      } else if (product.Type === 'Live') {
        return {
          LiveID: product.ID,
          Note: "Nota abbonamento"
        };
      } else {
        return {
          SubscriptionID: product.ID,
          Note: "Nota abbonamento"
        };
      }
    });

    if (promoCode) 
      payload.Coupons = [promoCode]
    else payload.Coupons = [];
 

  try {
    const response = await axiosAuthPublicInstance.post(`${ApiConfig.ROOT_URL}/orders/cart-total`, payload);
    return {
      SubTotal: response.data.SubTotal,
      SpecialDiscount: response.data.SpecialDiscount,
      Coupons: response.data.Coupons,
      Total: response.data.Total,
      VAT: response.data.VAT,
    }
  } catch (error) {
    return {
      SubTotal: 0,
      SpecialDiscount: 0,
      Coupons: [],
      Total: 0,
      VAT: 0,
    }
  }


    // return cartItems.reduce((total, item) => total + item.DiscountPrice, 0).toFixed(2);
  };

  const getCartLength = () => {
    return cartItems.length;
  };

  const getPromoCode = () => {
    return promoCode;
  };

  const applyPromoCode = (code) => {
    setPromoCode(code);
  };

  useEffect(() => {
    const cartString = JSON.stringify(cartItems);
    localStorage.setItem("cartItems", cartString);
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem("promoCode", promoCode);
  }, [promoCode]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    const cartObject = JSON.parse(cartItems);
    if (cartItems) {
      setCartItems(cartObject);
    }

    const promoCode = localStorage.getItem("promoCode");
    if (promoCode) {
      setPromoCode(promoCode);
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        isCourseAlreadyInCart,
        getCartLength,
        removeFromCart,
        clearCart,
        getCartTotal,
        getPromoCode,
        applyPromoCode,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};


