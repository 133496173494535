import { useEffect } from "react";
import AdminLayout from "../../layout/AdminLayout";
import { Link } from "react-router-dom";
import { TextDictionary } from "../../utils/TextDictionary";

const Dashboard = () => {
    const Card = (route: string, text: string, icon?: any) => {
        return (
            <Link to={route}>
                <div className="h-52 w-52 bg-white p-5 rounded-lg shadow-lg flex justify-center items-center flex-col gap-8">
                    <div className="w-16 h-16 bg-primary rounded-full flex justify-center items-center">
                        {icon}
                    </div>
                    <h2 className="text-lg text-center">{text}</h2>
                </div>
            </Link>
        );
    }

    return (
        <AdminLayout>
            <div className="relative flex flex-col w-full h-full">
                {/* Creo una griglia di 3 card e 2 righe dove ogni card avrà un icona e un testo che indicherà il nome della route a cui andare. al click sulla card si deve navigare alla route corrispondente */}
                <div className="flex flex-row gap-12 flex-wrap justify-center">
                    {Card(
                        "/admin/users",
                        TextDictionary.Admin.Dashboard.CollegamentoUtenti,
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                            <rect width="20px" height="20px" fill="transparent" />
                            <path d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z" fill="white" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 14C9.33333 14 4 15.3333 4 18V20H20V18C20 15.3333 14.6667 14 12 14Z" fill="white" />
                        </svg>
                    )}

                    {Card(
                        "/admin/subscriptions",
                        TextDictionary.Admin.Dashboard.CollegamentoAbbonamenti,
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <rect x="5" y="4" width="14" height="17" rx="2" stroke="#222222"/>
                        <path d="M9 9H15" stroke="#222222" strokeLinecap="round"/>
                        <path d="M9 13H15" stroke="#222222" strokeLinecap="round"/>
                        <path d="M9 17H13" stroke="#222222" strokeLinecap="round"/>
                        </svg>
                    )}

                    {Card(
                        "/admin/orders",
                        TextDictionary.Admin.Dashboard.CollegamentoOrdini,
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <rect x="5" y="4" width="14" height="17" rx="2" stroke="#222222"/>
                        <path d="M9 9H15" stroke="#222222" strokeLinecap="round"/>
                        <path d="M9 13H15" stroke="#222222" strokeLinecap="round"/>
                        <path d="M9 17H13" stroke="#222222" strokeLinecap="round"/>
                        </svg>
                    )}

                    {Card(
                        "/admin/courses",
                        TextDictionary.Admin.Dashboard.CollegamentoCorsi,
                        <svg width="30px" height="30px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1 2h16v11H1z" />
                            <path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M4 5.5v5s3-1 5 0v-5s-2-2-5 0zM9 5.5v5s3-1 5 0v-5s-2-2-5 0z" />
                            <path fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8.5 14l-3 3h7l-3-3z" />
                        </svg>
                    )}

                    {Card(
                        "/admin/lives",
                        TextDictionary.Admin.Dashboard.CollegamentoLive,
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="white" version="1.1" xmlns="http://www.w3.org/2000/svg"xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" strokeWidth="1" fill="white" fillRule="evenodd">
                            <g id="ic_fluent_live_24_regular" fill="white" fillRule="nonzero">
                                <path d="M5.98959236,4.92893219 C6.28248558,5.22182541 6.28248558,5.69669914 5.98959236,5.98959236 C2.67013588,9.30904884 2.67013588,14.6909512 5.98959236,18.0104076 C6.28248558,18.3033009 6.28248558,18.7781746 5.98959236,19.0710678 C5.69669914,19.363961 5.22182541,19.363961 4.92893219,19.0710678 C1.02368927,15.1658249 1.02368927,8.83417511 4.92893219,4.92893219 C5.22182541,4.63603897 5.69669914,4.63603897 5.98959236,4.92893219 Z M19.0710678,4.92893219 C22.9763107,8.83417511 22.9763107,15.1658249 19.0710678,19.0710678 C18.7781746,19.363961 18.3033009,19.363961 18.0104076,19.0710678 C17.7175144,18.7781746 17.7175144,18.3033009 18.0104076,18.0104076 C21.3298641,14.6909512 21.3298641,9.30904884 18.0104076,5.98959236 C17.7175144,5.69669914 17.7175144,5.22182541 18.0104076,4.92893219 C18.3033009,4.63603897 18.7781746,4.63603897 19.0710678,4.92893219 Z M8.81801948,7.75735931 C9.1109127,8.05025253 9.1109127,8.52512627 8.81801948,8.81801948 C7.06066017,10.5753788 7.06066017,13.4246212 8.81801948,15.1819805 C9.1109127,15.4748737 9.1109127,15.9497475 8.81801948,16.2426407 C8.52512627,16.5355339 8.05025253,16.5355339 7.75735931,16.2426407 C5.41421356,13.8994949 5.41421356,10.1005051 7.75735931,7.75735931 C8.05025253,7.46446609 8.52512627,7.46446609 8.81801948,7.75735931 Z M16.2426407,7.75735931 C18.5857864,10.1005051 18.5857864,13.8994949 16.2426407,16.2426407 C15.9497475,16.5355339 15.4748737,16.5355339 15.1819805,16.2426407 C14.8890873,15.9497475 14.8890873,15.4748737 15.1819805,15.1819805 C16.9393398,13.4246212 16.9393398,10.5753788 15.1819805,8.81801948 C14.8890873,8.52512627 14.8890873,8.05025253 15.1819805,7.75735931 C15.4748737,7.46446609 15.9497475,7.46446609 16.2426407,7.75735931 Z M12,10.5 C12.8284271,10.5 13.5,11.1715729 13.5,12 C13.5,12.8284271 12.8284271,13.5 12,13.5 C11.1715729,13.5 10.5,12.8284271 10.5,12 C10.5,11.1715729 11.1715729,10.5 12,10.5 Z" id="🎨-Color">

                                </path>
                            </g>
                        </g>
                    </svg>
                    )}

                    {Card(
                        "/admin/quizzes",
                        TextDictionary.Admin.Dashboard.CollegamentoQuiz,
                        <svg width="30px" height="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" fill="white">
                            <g fillRule="evenodd" clipRule="evenodd" stroke="none" strokeWidth="1">
                                <path d="M1468.235 113v99.388l-112.94 112.941v-99.388H112.94v1468.235h1242.353v-438.211l112.941-112.941v664.094H0V113h1468.235ZM421.271 1163.353c96-9.035 154.729 108.423 190.87 197.647 28.235 68.894 38.4 92.612 72.283 96 33.882 3.388 89.223-58.73 112.94-101.647 16.53-26.51 51.42-34.6 77.93-18.07 26.51 16.529 34.6 51.42 18.07 77.929-9.035 16.94-92.611 160.376-205.552 160.376h-9.036c-70.023-4.517-121.976-48.564-169.411-166.023-47.436-117.46-77.93-127.624-77.93-127.624a484.518 484.518 0 0 0-97.13 225.883c-6.549 31.187-37.14 51.16-68.329 44.611-31.187-6.55-51.16-37.141-44.611-68.33 20.33-94.87 79.059-310.587 199.906-320.752Zm256.376-485.647v112.941H338.824V677.706h338.823ZM903.53 451.824v112.94H338.824v-112.94h564.705Z" />
                                <path d="m1903.059 468.765-225.883-225.883a56.47 56.47 0 0 0-80.188 0L919.341 920.53a56.476 56.476 0 0 0-15.813 39.53v282.353h282.354a56.47 56.47 0 0 0 39.53-16.941l677.647-677.647c21.523-21.959 21.523-57.101 0-79.06Zm-740.894 660.706H1016.47V983.776l451.764-451.764 145.694 145.694-451.764 451.765Zm531.953-531.953-145.694-145.694 89.223-89.224 145.694 145.694-89.223 89.224Z" />
                            </g>
                        </svg>
                    )}

                    {Card(
                        "/admin/videos",
                        TextDictionary.Admin.Dashboard.CollegamentoVideo,
                        <svg fill="white" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19,2H5A1,1,0,0,0,4,3V21a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V3A1,1,0,0,0,19,2ZM14.577,12.416l-4.6,3.066a.5.5,0,0,1-.777-.416V8.934a.5.5,0,0,1,.777-.416l4.6,3.066A.5.5,0,0,1,14.577,12.416Z" /></svg>
                    )}

                    {Card(
                        "/admin/resources",
                        TextDictionary.Admin.Dashboard.CollegamentoRisorse,
                        <svg height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 280.067 280.067" xmlSpace="preserve">
                            <g>
                                <path fill="white" d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429
                                    c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0
                                    s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394
                                    c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599
                                    c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403
                                    c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485
                                    c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098
                                    l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"/>
                            </g>
                        </svg>)}

                    {Card(
                        "/admin/coupons",
                        TextDictionary.Admin.Dashboard.CollegamentoCoupons,
                        <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="coupon" fill="white"><path d="M20,4H14V6.5a1,1,0,0,1-2,0V4H4A2,2,0,0,0,2,6V9a1,1,0,0,0,1,1,2,2,0,0,1,0,4,1,1,0,0,0-1,1v3a2,2,0,0,0,2,2h8V17.5a1,1,0,0,1,2,0V20h6a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm-6,9.83a1,1,0,0,1-2,0V10.17a1,1,0,0,1,2,0Z"></path></svg>
                    )}

                </div>
            </div>
        </AdminLayout>
    );
};

export default Dashboard;