import { useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { Course } from "../models/course";
import { PublicVideo, Video } from "../models/video";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig, AppConfig } from "../config/Configuration";
import PublicLayout from "../layout/PublicLayout";
import { PrimaryButton } from "../components/PrimaryButton";
import { QuizComponent } from "../components/QuizComponent";
import { Quiz } from "../models/quiz";
import { TextDictionary } from "../utils/TextDictionary";
import Player from '@vimeo/player';
import { UserService } from "../services/user.service";
import Swal from "sweetalert2";
import { set } from "react-hook-form";
import { AuthService } from "../auth/sso/auth.service";

interface QuestionElem { // Da spostare in un file a parte
    title?: string;
    type: 'intro' | 'question';
    duration?: number;
    description?: string;
    question?: string;
    content?: string;
    special?: 'writing' | 'listening';
    answers: AnswerElem[];
}

interface AnswerElem { // Da spostare in un file a parte
    text: string;
    selected?: boolean;
    isCorrect?: boolean;
    points?: number;
}


export const PublicSingleLive = () => {
    const { id } = useParams();
    const live = useLoaderData() as any;


    const [showResourcesModal, setShowResourcesModal] = useState(false); // Mostra il modal con le risorse del corso.
    const [question, setQuestion] = useState<string>('');


    const downloadResource = async (resource: any, index: number) => {
        const allResources = document.querySelectorAll('.resource-element');
        for (let i = 0; i < allResources.length; i++) {
            allResources[i].classList.add("pointer-events-none");
            allResources[i].classList.add("opacity-50");
        }

        const response = await axiosAuthInstance.get(ApiConfig.ROOT_URL + resource.DownloadLink, {
            responseType: 'blob',
        }) as any;

        // faccio il download del file
        const file = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = file;
        a.download = resource.FileName;
        a.click();
        window.URL.revokeObjectURL(file);

        for (let i = 0; i < allResources.length; i++) {
            allResources[i].classList.remove("pointer-events-none");
            allResources[i].classList.remove("opacity-50");
        }
    }

    const submitQuestion = async () => {
        // Mostro un alert di conferma
        const confirm = await Swal.fire({
            title: 'Conferma invio domanda',
            text: 'Vuoi inviare la tua domanda? Il docente potrà risponderti durante la diretta.',
            showCancelButton: true,
            confirmButtonText: 'Invia',
            cancelButtonText: 'Annulla'
        });

        if (!confirm.isConfirmed) return;


        // DATI DA MANDARE A FABIO:
        // - Message
        // - LiveID

        try {
            const response = await axiosAuthInstance.post(ApiConfig.ROOT_URL + '/public/lives/message', {
                Message: question,
                LiveID: live.ID
            });

            if (response.status === 200) {
                Swal.fire({
                    title: 'Domanda inviata',
                    text: 'La tua domanda è stata inviata con successo.',
                    icon: 'success'
                });
            }

            setQuestion('');
        } catch (error: any) {
            Swal.fire({
                title: 'Errore durante l\'invio',
                text: error.response.data.message || 'Si è verificato un errore durante l\'invio della domanda.',
                icon: 'error'
            });
        }
    }

    return (
        <PublicLayout>
            <div className="container w-full mx-auto flex flex-col justify-center items-center h-full">
                <div className="flex flex-col w-full h-full max-w-[1400px] mx-auto lg:flex-row my-20 gap-20 px-8">
                    {/* Contenuto principale */}
                    <div className="w-full lg:w-2/3 h-full flex flex-col">
                        <h1 className="text-3xl font-semibold mb-4 text-primary flex flex-row gap-4 items-center">{live.Title}
                            <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296.89 184.4" height={30} width={30}>
                                <g id="Layer_1" data-name="Layer 1" fill="#ed1c24">
                                    <g>
                                        <g>
                                            <path d="M109.48,174.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-29.27c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h8.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v32.43h19.28Z" />
                                            <path d="M118.33,184.4c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h8.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v40.84c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-8.28Z" />
                                            <path d="M150.57,184.4c-1.64,0-2.76-.84-3.35-2.53l-12.9-39.51c-.08-.29-.14-.48-.16-.57-.02-.08-.03-.17-.03-.25,0-.38.14-.71.41-.98.27-.27.6-.41.98-.41h7.97c.84,0,1.44.18,1.8.54.36.36.58.71.66,1.04l8.79,27.82,8.79-27.82c.08-.34.31-.68.66-1.04.36-.36.96-.54,1.8-.54h7.97c.38,0,.71.14.98.41.27.27.41.6.41.98,0,.08-.01.17-.03.25-.02.08-.07.27-.16.57l-12.9,39.51c-.59,1.69-1.71,2.53-3.35,2.53h-8.34Z" />
                                            <path d="M212.84,174.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-29.97c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h29.46c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-19.47v6.83h18.08c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-18.08v7.08h19.98Z" />
                                        </g>
                                        <path d="M148.95,48.5c-15.53,0-28.12,12.59-28.12,28.12s12.59,28.12,28.12,28.12,28.12-12.59,28.12-28.12-12.59-28.12-28.12-28.12ZM166.7,79.02l-13.43,7.75-13.43,7.75c-1.85,1.07-4.16-.27-4.16-2.4v-31.01c0-2.13,2.31-3.47,4.16-2.4l13.43,7.75,13.43,7.75c1.85,1.07,1.85,3.73,0,4.8Z" />
                                        <g>
                                            <path d="M233.04,133.36c-1.97,0-3.96-.54-5.74-1.68-4.97-3.17-6.43-9.78-3.26-14.76,7.67-12.02,11.73-25.96,11.73-40.29s-4.06-28.28-11.73-40.3c-3.18-4.97-1.72-11.58,3.26-14.76,4.97-3.18,11.58-1.72,14.76,3.26,9.87,15.47,15.09,33.38,15.09,51.8s-5.22,36.33-15.08,51.79c-2.04,3.19-5.49,4.94-9.02,4.94Z" />
                                            <path d="M267.49,153.25c-1.92,0-3.86-.52-5.62-1.6-5.02-3.11-6.57-9.69-3.46-14.71,11.19-18.08,17.1-38.93,17.1-60.31s-5.91-42.23-17.1-60.31c-3.11-5.02-1.56-11.61,3.46-14.71,5.02-3.11,11.61-1.56,14.71,3.46,13.28,21.46,20.3,46.2,20.3,71.56s-7.02,50.1-20.3,71.56c-2.02,3.27-5.52,5.06-9.1,5.06Z" />
                                            <path d="M198.85,113.01c-2.06,0-4.14-.59-5.97-1.83-4.89-3.3-6.18-9.95-2.87-14.84,3.94-5.83,6.02-12.64,6.02-19.71s-2.16-14.15-6.25-20.05c-3.36-4.85-2.15-11.51,2.7-14.87,4.85-3.36,11.51-2.15,14.87,2.7,6.57,9.49,10.05,20.63,10.05,32.22s-3.35,22.3-9.68,31.68c-2.07,3.06-5.44,4.71-8.87,4.71Z" />
                                        </g>
                                        <g>
                                            <path d="M63.85,133.36c-3.53,0-6.98-1.75-9.02-4.94-9.87-15.46-15.09-33.37-15.09-51.79s5.22-36.34,15.09-51.8c3.18-4.98,9.78-6.43,14.76-3.26,4.98,3.18,6.44,9.78,3.26,14.76-7.67,12.02-11.73,25.96-11.73,40.3s4.06,28.27,11.73,40.29c3.18,4.98,1.71,11.58-3.26,14.76-1.78,1.14-3.77,1.68-5.74,1.68Z" />
                                            <path d="M29.4,153.25c-3.58,0-7.08-1.8-9.1-5.06C7.02,126.72,0,101.98,0,76.62S7.02,26.52,20.3,5.06C23.41.04,30-1.51,35.02,1.6c5.02,3.11,6.57,9.69,3.46,14.71-11.19,18.08-17.1,38.93-17.1,60.31s5.91,42.23,17.1,60.31c3.11,5.02,1.56,11.61-3.46,14.71-1.75,1.08-3.7,1.6-5.62,1.6Z" />
                                            <path d="M98.04,113.01c-3.43,0-6.8-1.65-8.87-4.71-6.33-9.37-9.68-20.33-9.68-31.68s3.48-22.72,10.05-32.22c3.36-4.85,10.02-6.06,14.87-2.7,4.85,3.36,6.06,10.02,2.7,14.87-4.09,5.9-6.25,12.83-6.25,20.05s2.08,13.89,6.02,19.71c3.3,4.89,2.02,11.53-2.87,14.84-1.83,1.24-3.91,1.83-5.97,1.83Z" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </h1>
                        <span className="text-md mb-4 text-primary">{TextDictionary.PaginaLive.LabelAggiornatoAl} {live.Date}</span>
                        {live.Teacher && live.Teacher !== '' &&
                            <span className="text-md mb-4 text-primary">{TextDictionary.PaginaLive.LabelDocente} {live.Teacher}</span>
                        }

                        {/* divisore */}
                        <div className="w-full border-t border-[#686868] mb-10"></div>

                        <div className={`public-video-iframe-container w-full bg-[#F4F4F4] mb-8`}
                            dangerouslySetInnerHTML={{ __html: live?.LiveMetadata.PlayerEmbedURL || '' }}
                        ></div>

                        {
                            live.Description && live.Description !== '' && (
                                <span className="text-primary font-medium mb-4">{TextDictionary.PaginaLive.LabelIntroduzione}</span>
                            )
                        }
                        <span dangerouslySetInnerHTML={{ __html: live.Description || '' }} className="text-black" />
                    </div>
                    {/* Modale risorse del corso e della lezione */}
                    {
                        showResourcesModal && (
                            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center px-4 lg:px-0">
                                <div className="w-full lg:w-1/3 bg-white p-8 rounded-[20px]">
                                    <div className="w-full flex justify-end items-center">
                                        <button onClick={() => setShowResourcesModal(false)} className="text-primary">
                                            <svg width="20" height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="w-full mb-8 flex flex-col gap-2">
                                        <span className="text-primary font-medium text-lg">{TextDictionary.PaginaLive.LabelRisorseLezione}</span>
                                        {
                                            live.ResourceData.find(
                                                (resource: any) => resource.Origin === 'live'
                                            ) ? live.ResourceData?.map((resource: any, index: number) => {
                                                if (resource.Origin === 'live') {
                                                    return (
                                                        <div key={index} className="w-full flex items-center">
                                                            <span id={"live-resource-" + index} className="resource-element selection:text-black underline cursor-pointer" onClick={() => {
                                                                downloadResource(resource, index);
                                                            }}>{resource.Title}</span>
                                                        </div>
                                                    )
                                                }
                                            }) : <span className="text-black">{TextDictionary.PaginaLive.LabelNessunaRisorsaLezione}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }


                    {/* Sidebar */}
                    <div className="w-full lg:w-1/3 h-full flex flex-col">
                        <div className="w-full flex flex-col justify-center mb-16">
                            <span className="text-black text-center text-sm">{TextDictionary.PaginaLive.LabelRisorse}</span>
                            <button className="w-[200px] h-12 bg-customYellow text-primary font-medium rounded-[15px] mt-5 mx-auto flex gap-4 justify-center items-center"
                                onClick={() => setShowResourcesModal(!showResourcesModal)}
                            >
                                <svg width="20px" height="20px" fill={AppConfig.primaryColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" x="0px" y="0px"><g data-name="Layer 2"><path d="M22,15.75A1.252,1.252,0,0,0,20.75,17v3a.752.752,0,0,1-.75.75H4A.752.752,0,0,1,3.25,20V17a1.25,1.25,0,0,0-2.5,0v3A3.254,3.254,0,0,0,4,23.25H20A3.254,3.254,0,0,0,23.25,20V17A1.252,1.252,0,0,0,22,15.75Z" /><path d="M12,.75A1.252,1.252,0,0,0,10.75,2V13.331L6.8,10.04A1.251,1.251,0,0,0,5.04,10.2,1.251,1.251,0,0,0,5.2,11.96l6,5a1.269,1.269,0,0,0,.2.116,1.179,1.179,0,0,0,.143.082,1.174,1.174,0,0,0,.911,0,1.3,1.3,0,0,0,.14-.081,1.279,1.279,0,0,0,.2-.116l6-5A1.25,1.25,0,0,0,18,9.75a1.255,1.255,0,0,0-.8.29L13.25,13.331V2A1.252,1.252,0,0,0,12,.75Z" /></g></svg>
                                {TextDictionary.PaginaLive.BottoneScaricaRisorse}
                            </button>
                        </div>



                        <span className="text-primary font-medium mb-4 text-lg">Ciao {UserService.getInstance().user?.Name}! hai qualche domanda?</span>
                        <textarea
                            className="w-full h-[200px] bg-white border border-black p-4 resize-none text-black"
                            placeholder="Scrivi qui la tua domanda"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                        <div className="w-full flex justify-end">
                            <button className="w-[150px] h-12 bg-primary text-white font-medium rounded-[15px] mt-5 flex gap-4 justify-center items-center"
                                onClick={submitQuestion}
                                style={{ backgroundColor: question === '' ? '#ccc' : AppConfig.primaryColor, cursor: question === '' ? 'not-allowed' : 'pointer' }}
                                disabled={question === ''}
                            >
                                Invia
                            </button>
                        </div>
                        <div>
                            <div className="w-full flex justify-end items-center mt-4">
                        {
                            // Controllo se l'utente è admin
                            AuthService.getInstance().isAdmin && (
                                <PrimaryButton text={TextDictionary.ListaCorsi.VaiAlleDomande} onClick={() => {
                                    // window.location.href = `/admin/lives/${live.ID}/questions`;

                                    // Riscrivo con target blank per aprire in un'altra scheda
                                    window.open(`/admin/lives/${live.ID}/questions`, '_blank');
                                }} />
                            )
                        }
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {quizData && quizStarted && (<QuizComponent quizData={quizData} />)} */}
        </PublicLayout>
    )
}