import { useEffect, useState } from "react";
import { Course } from "../models/course";
import axios from "axios";
import { ProductCard } from "./ProductCard";
import { ApiConfig } from "../config/Configuration";
import { TextDictionary } from "../utils/TextDictionary";
import Slider, { Settings } from "react-slick";
import FrecciaDx from "../assets/images/freccia-dx.png";
import FrecciaSx from "../assets/images/freccia-sx.png";

interface FeauturedProductsProps {
    inverse?: boolean;
}

const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} absolute top-1/2 -right-16 transform -translate-y-1/2`}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <img src={FrecciaDx} alt="freccia destra" className="w-18 h-18" />
        </div>
    );
}

const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} absolute top-1/2 -left-16 transform -translate-y-1/2`}
            style={{ ...style, display: "block"}}
            onClick={onClick}
        >
            <img src={FrecciaSx} alt="freccia sinistra" className="w-18 h-18" />
        </div>
    );
}

export const FeauturedProducts = ({ inverse = true }: FeauturedProductsProps) => {
    // Effettuo uno scrollTo(0, 0) per far tornare la pagina in cima

    const [courses, setCourses] = useState<Course[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const sliderSettings: Settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true
                }
            }
        ]
    }



    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${ApiConfig.ROOT_URL}/public/classes`, {
                    params: {
                        featured: true
                    }
                });
                // setto courses solo con i corsi che hanno Featured = true
                setCourses(response.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, []);

    if (loading) {
        return (
            <div className="w-full flex flex-col">
                <span className="text-center text-md text-black text-opacity-80">Caricamento in corso...</span>
            </div>
        )
    }

    if (courses.length === 0) {
        return (
            <div className="w-full flex flex-col">
                <span className="text-center text-md text-black text-opacity-80">Nessun corso in evidenza disponibile</span>
            </div>
        )
    }
    
    return (
        <div className="w-full flex flex-col">
            <div className="w-full px-0 md:px-14">
                <Slider {...sliderSettings} >
                    {
                        courses.map((course: any) => (
                            <ProductCard key={course.ID} element={course} inverse={inverse} isSliderElem/>
                        ))
                    }
                </Slider>
            </div>
        </div>
    );
}