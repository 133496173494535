import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { AuthService } from '../../auth/sso/auth.service';
import IDPLayout from '../../layout/IDPLayout';
import { useForm } from 'react-hook-form';
import { User } from '../../auth/sso/auth.types';
import { saveFunction } from '../../core/saveFunction';
import Swal from 'sweetalert2';
import { TextDictionary } from '../../utils/TextDictionary';
import PublicLayout from '../../layout/PublicLayout';

const SignIn = () => {
    const { state } = useLocation(); // Ottengo lo stato della location
    const { toPath } = state || { toPath: undefined }; // Se lo stato non è definito, toPath è undefined. Altrimenti, toPath è uguale a state.toPath


    const navigate = useNavigate();
    const authService = AuthService.getInstance();

    const { register, handleSubmit, formState: { errors } } = useForm<User>({
        defaultValues: {
            Email: '',
            Password: ''
        }
    });
    const onSubmit = (data: User) => submitSignin(data);

    const submitSignin = async (data: User) => {
        const loginPayload = {
            Email: data.Email,
            Password: data.Password
        };

        try {
            const isLoggedIn = await authService.signIn(loginPayload);
            if (isLoggedIn) {
                Swal.fire({
                    title: 'Accesso effettuato',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });

                // Torno alla pagina da cui sono partito
                if (toPath) {
                    navigate(toPath);
                } else {
                    navigate('/');
                }
            }
        } catch (error: any) {
            console.error("---- signin error", error);
            Swal.fire({
                title: 'Errore in fase di autenticazione!',
                text: TextDictionary.PagineIDP[error.response.data.message as keyof typeof TextDictionary.PagineIDP],
                icon: 'error'
            });
        }
    };

    return (
        <PublicLayout>
            <div className='h-full mx-auto max-w-screen-md md:p-6 flex flex-col items-center justify-center'>
                <div className="w-full py-14 px-6 md:px-0 md:py-0">
                    <span className="mb-1.5 block font-medium bodoni">
                        {TextDictionary.PagineIDP.Sottotitolo}
                    </span>
                    <h2 className="mb-9 text-3xl font-bold text-black">
                        {TextDictionary.PagineIDP.TitoloLogin}
                    </h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-8">
                            <label className="mb-2.5 block font-medium text-black ">
                                {TextDictionary.PagineIDP.CampoEmail}
                            </label>
                            <div className="relative">
                                <input
                                    type="email"
                                    placeholder="Inserisci la tua email"
                                    className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                    {...register('Email', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                                />
                                {errors.Email && <p className="absolute text-errorRed text-sm">{errors.Email.message}</p>}
                            </div>
                        </div>
                        <div className="mb-10">
                            <label className="mb-2.5 block font-medium text-black ">
                                {TextDictionary.PagineIDP.CampoPassword}
                            </label>
                            <div className="relative">
                                <input
                                    type="password"
                                    placeholder="**********"
                                    className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                    {...register('Password', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                                />
                                {errors.Password && <p className="absolute text-errorRed text-sm">{errors.Password.message}</p>}
                            </div>
                        </div>

                        <div className="mb-5">
                            <input
                                type="submit"
                                value="Accedi"
                                className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                            />
                        </div>

                        <div className="mt-6 flex flex-col md:flex-row gap-4 md:gap-0 justify-between">
                            <p>
                                {TextDictionary.PagineIDP.BottoneNonHaiAccount}{' '}
                                <Link to="/signup" className="text-primary underline">
                                    {TextDictionary.PagineIDP.BottoneRegistrati}
                                </Link>
                            </p>
                            <p>
                                <Link to="/forgot-password" className="text-primary">
                                    {TextDictionary.PagineIDP.BottonePasswordDimenticata}
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>

        </PublicLayout>
    );
};

export default SignIn;
