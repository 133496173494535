import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { TableComponent } from "../../../components/TableComponent";
import AdminLayout from "../../../layout/AdminLayout";
import { TextDictionary } from "../../../utils/TextDictionary";
import { useEffect } from "react";

export const SingleLiveQuestions = () => {
    const navigate = useNavigate();
    const live = useLoaderData() as any | null;

    // Faccio in modo che ogni 30 secondi la pagina si ricarichi
    // in modo da visualizzare le domande in tempo reale

    useEffect(() => {
        const interval = setInterval(() => {
            window.location.reload();
        }, 30000); // 30 secondi
        return () => clearInterval(interval);
    }, []);
    
    return (
        <AdminLayout>
            <div className="relative flex flex-col w-full h-full">
                <div className="flex flex-row justify-between w-full mb-8">
                    <h1 className="text-3xl font-semibold hover:underline cursor-pointer" 
                    onClick={() => {
                        navigate(`/admin/lives/${live.ID}`);
                    }}
                    >{live.Title} - {TextDictionary.Admin.Live.TestoPaginaDomande}</h1>
                    <button className="bg-primary text-white font-medium py-2 px-4 rounded" onClick={() => window.location.reload()}>
                        {TextDictionary.Admin.Live.BottoneAggiornaDomande}
                    </button>
                </div>
                <TableComponent
                    endpoint={`/live-chats/${live.ID}`}
                    dictionaryContext={"DomandeLive"}
                />
            </div>
        </AdminLayout>
    );
};