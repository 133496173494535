import { useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { unstable_usePrompt, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { TextDictionary } from "../../../utils/TextDictionary";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import { Resource } from "../../../models/resource";
import { Controller, useForm } from "react-hook-form";
import { saveFunction } from "../../../core/saveFunction";
import Swal from "sweetalert2";
import { Subscription } from "../../../models/subscription";
import { EditorProvider, Editor, Toolbar, BtnBold, BtnItalic } from "react-simple-wysiwyg";
import Select from 'react-select';

const SingleSubscription = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const subscription = useLoaderData() as any | null;

    const { register, handleSubmit, getValues, setValue, control, formState: { errors, isDirty, isSubmitting }, reset } = useForm<Subscription>({
        defaultValues: {
            Title: subscription?.Title || '',
            Description: subscription?.Description || '',
            ShortDescription: subscription?.ShortDescription || '',
            RegularPrice: subscription?.RegularPrice || 0,
            DiscountPrice: subscription?.DiscountPrice || 0,
            Duration: subscription?.Duration || 365,
            EmployeesNumber: subscription?.EmployeesNumber || 0,
            Type: subscription?.Type || 'live_only',
            Purchasable: subscription?.Purchasable || false,
        }
    });

    const [selectedType, setSelectedType] = useState<any | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);
    const onSubmit = (data: Resource, saveAndClose?: boolean) => saveFunction(data, id, "subscriptions", "Abbonamenti", setIsSaving, navigate, saveAndClose); // Invoco la funzione di salvataggio 
    // passando i parametri necessari

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        if (subscription) {
            reset({
                Title: subscription.Title,
                Description: subscription.Description,
                ShortDescription: subscription.ShortDescription,
                RegularPrice: subscription.RegularPrice,
                DiscountPrice: subscription.DiscountPrice,
                Duration: subscription.Duration,
                EmployeesNumber: subscription.EmployeesNumber,
                Type: subscription.Type,
                Purchasable: subscription.Purchasable,
            });

            switch (subscription.Type) {
                case 'live_only':
                    setSelectedType({ value: 'live_only', label: 'Solo live' });
                    break;
                case 'course_only':
                    setSelectedType({ value: 'course_only', label: 'Solo corsi' });
                    break;
                case 'live_and_course':
                    setSelectedType({ value: 'live_and_course', label: 'Live e corsi' });
                    break;
                default:
                    setSelectedType(null);
                    break;
            }
        }
    }, [subscription, reset]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname
    });

    const handleDiscard = () => {
        navigate('/admin/subscriptions');
    };

    const handleSave = (saveAndClose?: boolean) => {
        handleSubmit(() => onSubmit(getValues(), saveAndClose))()
            .then((e) => {
                setToggleAlert(!toggleAlert);
            },
                (error) => {
                    const errorMessages = [];
                    for (const key in error.response.data.errors) {
                        errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
                    }
                    Swal.fire({
                        title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                        text: errorMessages.join('\n')
                    });
                }
            );
    }

    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Abbonamenti.ModificaAbbonamento : TextDictionary.Admin.Abbonamenti.NuovoAbbonamento}</h1>

                    <div className="flex flex-row w-full gap-8">
                        <div className="flex flex-col gap-4 w-full flex-1">
                            <div className="module-card flex flex-col w-full">
                                <div className="flex flex-row items-center justify-between gap-4">
                                    {/* Nome */}
                                    <div className="flex flex-col mb-4 w-full">
                                        <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Abbonamenti.CampoTitolo}*</label>
                                        <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                            {...register("Title", { required: TextDictionary.Admin.Form.CampoObbligatorio })}
                                            autoFocus
                                        />
                                        {errors.Title && <p role="alert" className="text-errorRed text-sm">{errors.Title.message}</p>}
                                    </div>
                                </div>
                                {/* Descrizione breve */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Abbonamenti.CampoDescrizioneBreve}*</label>
                                    <Controller
                                        name="ShortDescription"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <EditorProvider>
                                                <Editor
                                                    onChange={(e) => {
                                                        // Controllo se la lunghezza del testo è maggiore di 100 caratteri
                                                        if (e.target.value.length > 100)
                                                            return;
                                                        onChange(e.target.value)
                                                    }
                                                    }
                                                    value={value}
                                                    className="border border-black border-opacity-10 w-full resize-none px-2"
                                                    containerProps={{ style: { height: '200px', width: '100%', overflowY: 'auto' } }}
                                                    name="ShortDescription"
                                                >
                                                    <Toolbar>
                                                        <BtnBold />
                                                        <BtnItalic />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        )}
                                    />
                                </div>

                                {/* Descrizione */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Abbonamenti.CampoDescrizione}*</label>
                                    <Controller
                                        name="Description"
                                        control={control}       
                                        render={({ field: { onChange, value, ref } }) => (
                                            <EditorProvider>
                                                <Editor
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    className="border border-black border-opacity-10 w-full resize-none px-2"
                                                    containerProps={{ style: { height: '200px', width: '100%', overflowY: 'auto' } }}
                                                    name="Description"
                                                >
                                                    <Toolbar>
                                                        <BtnBold />
                                                        <BtnItalic />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        )}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="flex flex-col gap-4 w-full flex-1">
                            {/* Tipo e numero di dipendenti */}
                            <div className="module-card flex flex-row mb-4 gap-8 w-full">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Abbonamenti.CampoTipo}*</label>
                                    <Controller
                                        name="Type"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Select
                                                className='mb-4'
                                                options={[
                                                    { value: 'live_only', label: 'Solo live' },
                                                    { value: 'course_only', label: 'Solo corsi' },
                                                    { value: 'live_and_course', label: 'Live e corsi' },
                                                ]}
                                                value={selectedType}
                                                isSearchable
                                                isClearable // Permette di svuotare il campo di ricerca
                                                onChange={(selected: any) => {
                                                    setSelectedType(selected);
                                                    setValue("Type", selected?.value || null);
                                                    onChange(selected?.value || null);
                                                }}

                                                closeMenuOnSelect={true}  // Mantiene il menu aperto dopo la selezione
                                                hideSelectedOptions={false}  // Mantiene le opzioni selezionate visibili nella lista
                                                placeholder="Cerca tipologia..."
                                            />
                                        )}
                                    />
                                    {errors.Type && <p role="alert" className="text-errorRed text-sm">{errors.Type.message}</p>}
                                </div>
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Abbonamenti.CampoNumeroDipendenti}*</label>
                                    <input type="number" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register("EmployeesNumber", { required: TextDictionary.Admin.Form.CampoObbligatorio, valueAsNumber: true })}
                                    />
                                    {errors.EmployeesNumber && <p role="alert" className="text-errorRed text-sm">{errors.EmployeesNumber.message}</p>}
                                </div>
                            </div>

                            {/* Durata */}
                            <div className="module-card flex flex-row mb-4 gap-8 w-full">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Abbonamenti.CampoDurata}*</label>
                                    <input type="number" id="name" className="border border-black border-opacity-10 w-1/3 px-2 py-1"
                                        {...register("Duration", { required: TextDictionary.Admin.Form.CampoObbligatorio, valueAsNumber: true })}
                                    />
                                    {errors.Duration && <p role="alert" className="text-errorRed text-sm">{errors.Duration.message}</p>}
                                </div>
                            </div>

                            {/* Prezzo */}
                            <div className="module-card flex flex-row mb-4 gap-8 w-full">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Abbonamenti.CampoPrezzo}*</label>
                                    <div className="flex flex-row gap-2 items-center">
                                        <input type="number" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                            {...register("RegularPrice", { required: TextDictionary.Admin.Form.CampoObbligatorio, valueAsNumber: true })}
                                        />
                                        <span className="font-semibold text-md">€</span>
                                    </div>
                                    {errors.RegularPrice && <p role="alert" className="text-errorRed text-sm">{errors.RegularPrice.message}</p>}
                                </div>
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Abbonamenti.CampoPrezzoScontato}*</label>
                                    <div className="flex flex-row gap-2 items-center">
                                        <input type="number" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                            {...register("DiscountPrice", { valueAsNumber: true })}
                                        />
                                        <span className="font-semibold text-md">€</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }}
                    onSaveAndClose={() => {
                        handleSave(true);
                    }} onDiscard={() => {
                        handleDiscard();
                    }}
                    isSubmitting={isSubmitting}
                />
            </div>


        </AdminLayout>
    );
};

export default SingleSubscription;