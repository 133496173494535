import { useContext, useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig } from "../config/Configuration";
import { Checkout } from "./Checkout";
import { CartContext } from "../core/cartProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { UserService } from "../services/user.service";

const stripePromise = loadStripe('pk_test_51QVCo9BQN2JLpEWORz757kaaDOGayROpZmzs3uVQ0HaVJgmK7HoCrJCypPDUX4suUsMXahxvYRUrdXwoAgnzVswr00RAIQXLNw'); // Chiave pubblica di test di Stripe

export interface BeginCheckoutRequest {
    Note?: string;
    OrderRows?: {
        SubscriptionID?: number;
        CourseID?: number;
        Note?: string;
    }[];
    Coupons?: string[];
    IntentID?: string;
}

export interface PaymentProps {
    clientSecret: string | null;
    intentID: string | null;
    checkoutBody: BeginCheckoutRequest | null;
    stripeProductID: string | null;
    stripePriceID: string | null;
    stripeSubscriptionID: string | null;
}

const Payment = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [options, setOptions] = useState<StripeElementsOptions | undefined>(undefined);

    useEffect(() => {
        if (!location.state) {
            navigate("/cart");
            return;
        }

        if (location.state.clientSecret){
            setOptions({
                clientSecret: location.state.clientSecret,
            });
        }
    }, [location]);

    return (
        options && location.state.intentID && location.state.checkoutBody ? ( // Se clientSecret, intentID, checkoutBody e l'utente sono stati valorizzati correttamente
            <Elements stripe={stripePromise} options={options}>
                <Checkout
                    intentID={location.state.intentID}
                    checkoutBody={location.state.checkoutBody}
                    stripeProductID={location.state.stripeProductID}
                    stripePriceID={location.state.stripePriceID}
                    stripeSubscriptionID={location.state.stripeSubscriptionID}     
                />
            </Elements>
        ) : (
            <PublicLayout>
                <div className="flex flex-col items-center justify-center h-full w-full">
                    <h1 className="text-xl font-medium mb-8 text-primary">{TextDictionary.Carrello_Checkout.Inizializzazione}</h1>
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
                </div>
            </PublicLayout>
        )
    );
};

export default Payment;
