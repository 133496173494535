import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { AuthService } from '../../auth/sso/auth.service';
import IDPLayout from '../../layout/IDPLayout';
import { useForm } from 'react-hook-form';
import { User } from '../../auth/sso/auth.types';
import { saveFunction } from '../../core/saveFunction';
import Swal from 'sweetalert2';
import { TextDictionary } from '../../utils/TextDictionary';
import PublicLayout from '../../layout/PublicLayout';
import { AppConfig } from '../../config/Configuration';

const Signup = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<User>({
        defaultValues: {
            FirstName: '',
            LastName: '',
            Email: '',
            Password: '',
            ConfirmPassword: '',
            Societa: '',
        }
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onSubmit = (data: User) => submitSignup(data);

    const authService = AuthService.getInstance();
    const navigate = useNavigate();

    const submitSignup = async (data: User) => {
        if (data.Password !== data.ConfirmPassword) {
            Swal.fire({
                title: 'Attenzione!',
                text: 'Le password non corrispondono.',
                icon: 'error'
            });
            return;
        }

        const signupPayload = {
            Name: data.FirstName,
            Surname: data.LastName,
            Email: data.Email,
            Password: data.Password,
        } as any;

        if (data.Societa && data.Societa !== '')
            signupPayload.Company = data.Societa;

        try {
            const response = await authService.signUp(signupPayload);
            if (response) {
                Swal.fire({
                    title: TextDictionary.PagineIDP.TitoloPopupConfermaRegistrazione,
                    text: TextDictionary.PagineIDP.TestoPopupConfermaRegistrazione,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                });

                navigate('/signin'); // Reindirizzo alla home (DA MODIFICARE CON LA PAGINA DOVE REINDIRIZZARE DOPO IL LOGIN)
            }
        } catch (error: any) {
            console.error("---- signup error", error);

            Swal.fire({
                title: 'Errore in fase di registrazione!',
                text: TextDictionary.PagineIDP[error.response.data.message as keyof typeof TextDictionary.PagineIDP] || error.response.data.message,
                icon: 'error'
            });
        }
    };

    return (
        <PublicLayout>
            <div className='h-full mx-auto max-w-screen-md md:p-6 flex flex-col items-center justify-center'>
                <div className="w-full py-14 px-6 md:px-0 md:py-0">
                    <span className="mb-1.5 block font-medium bodoni">
                        {TextDictionary.PagineIDP.Sottotitolo}
                    </span>
                    <h2 className="mb-9 text-3xl font-bold text-black">
                        {TextDictionary.PagineIDP.TitoloRegistrazione}
                    </h2>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className="mb-2 md:mb-8">
                            <label className="mb-2.5 block font-medium text-black ">
                                {TextDictionary.PagineIDP.CampoNome}*
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Inserisci il tuo nome"
                                    className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                    {...register('FirstName', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                                />
                                {errors.FirstName && <p className="absolute text-errorRed text-sm">{errors.FirstName.message}</p>}
                            </div>
                        </div>
                        <div className="mb-2 md:mb-8">
                            <label className="mb-2.5 block font-medium text-black">
                                {TextDictionary.PagineIDP.CampoCognome}*
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Inserisci il tuo cognome"
                                    className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                    {...register('LastName', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                                />
                                {errors.LastName && <p className="absolute text-errorRed text-sm">{errors.LastName.message}</p>}
                            </div>
                        </div>
                        <div className="mb-2 md:mb-8">
                            <label className="mb-2.5 block font-medium text-black">
                                {TextDictionary.PagineIDP.CampoEmail}*
                            </label>
                            <div className="relative">
                                <input
                                    type="email"
                                    placeholder="Inserisci la tua email"
                                    className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                    {...register('Email', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                                />
                                {errors.Email && <p className="absolute text-errorRed text-sm">{errors.Email.message}</p>}
                            </div>
                        </div>
                        <div className="mb-2 md:mb-8">
                            <label className="mb-2.5 block font-medium text-black">
                                {TextDictionary.PagineIDP.CampoSocieta}
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Inserisci il nome della tua società"
                                    className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                    {...register('Societa')}
                                />
                            </div>
                        </div>
                        <div className="mb-10">
                            <label className="mb-2.5 block font-medium text-black">
                                {TextDictionary.PagineIDP.CampoPassword}*
                            </label>
                            <div className="relative">
                                <div className='relative'>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        placeholder="**********"
                                        className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                        {...register('Password', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                                    />
                                    <svg
                                        width="35px"
                                        height="35px"
                                        viewBox="0 0 76 76"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        baseProfile="full"
                                        enable-background="new 0 0 76.00 76.00"
                                        xmlSpace="preserve"
                                        className='absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer'
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        <path fill={showPassword ? AppConfig.primaryColor : 'gray'} fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 38,33.1538C 40.6765,33.1538 42.8462,35.3235 42.8462,38C 42.8462,40.6765 40.6765,42.8461 38,42.8461C 35.3235,42.8461 33.1539,40.6765 33.1539,38C 33.1539,35.3235 35.3236,33.1538 38,33.1538 Z M 38,25.0769C 49.3077,25.0769 59,33.1538 59,38C 59,42.8461 49.3077,50.9231 38,50.9231C 26.6923,50.9231 17,42.8461 17,38C 17,33.1538 26.6923,25.0769 38,25.0769 Z M 38,29.1154C 33.0932,29.1154 29.1154,33.0932 29.1154,38C 29.1154,42.9068 33.0932,46.8846 38,46.8846C 42.9068,46.8846 46.8846,42.9068 46.8846,38C 46.8846,33.0932 42.9068,29.1154 38,29.1154 Z " />
                                    </svg>
                                </div>
                                {errors.Password && <p className="absolute text-errorRed text-sm">{errors.Password.message}</p>}
                            </div>

                            <ul className=" text-xs text-gray-500 list-none mt-10 pl-0">
                                <li>Almeno 8 caratteri</li>
                                <li>Almeno 1 lettera</li>
                                <li>Almeno 1 numero</li>
                                <li>Almeno 1 carattere speciale tra !#$%@&'()*+,-.</li>
                            </ul>
                        </div>

                        <div className="mb-10">
                            <label className="mb-2.5 block font-medium text-black">
                                {TextDictionary.PagineIDP.CampoConfermaPassword}*
                            </label>
                            <div className="relative">
                                <div className='relative'>
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        placeholder="**********"
                                        className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                        {...register('ConfirmPassword', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                                    />
                                    <svg
                                        width="35px"
                                        height="35px"
                                        viewBox="0 0 76 76"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        baseProfile="full"
                                        enable-background="new 0 0 76.00 76.00"
                                        xmlSpace="preserve"
                                        className='absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer'
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        <path fill={showConfirmPassword ? AppConfig.primaryColor : 'gray'}
                                        fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 38,33.1538C 40.6765,33.1538 42.8462,35.3235 42.8462,38C 42.8462,40.6765 40.6765,42.8461 38,42.8461C 35.3235,42.8461 33.1539,40.6765 33.1539,38C 33.1539,35.3235 35.3236,33.1538 38,33.1538 Z M 38,25.0769C 49.3077,25.0769 59,33.1538 59,38C 59,42.8461 49.3077,50.9231 38,50.9231C 26.6923,50.9231 17,42.8461 17,38C 17,33.1538 26.6923,25.0769 38,25.0769 Z M 38,29.1154C 33.0932,29.1154 29.1154,33.0932 29.1154,38C 29.1154,42.9068 33.0932,46.8846 38,46.8846C 42.9068,46.8846 46.8846,42.9068 46.8846,38C 46.8846,33.0932 42.9068,29.1154 38,29.1154 Z " />
                                    </svg>
                                </div>
                                {errors.ConfirmPassword && <p className="absolute text-errorRed text-sm">{errors.ConfirmPassword.message}</p>}
                            </div>
                        </div>

                        <div className="mb-5">
                            <input
                                type="submit"
                                value="Registrati"
                                className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                            />
                        </div>

                        <div className="mt-6 flex flex-col md:flex-row gap-4 md:gap-0 justify-end">
                            <p>
                                {TextDictionary.PagineIDP.BottoneHaiAccount}{' '}
                                <Link to="/signin" className="text-primary underline">
                                    {TextDictionary.PagineIDP.BottoneAccedi}
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>

        </PublicLayout>
    );
};

export default Signup;