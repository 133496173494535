import { AuthUser } from "../auth/interfaces/user.interface";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { AxiosResponse } from "axios";
import { AuthService } from "../auth/sso/auth.service";
import { ApiConfig } from "../config/Configuration";

export enum IDPPermission {
  CAN_VIEW_USER = "CanViewUser",
  CAN_EDIT_USER = "CanEditUser",

  CAN_VIEW_COURSE = "CanViewCourse",
  CAN_EDIT_COURSE = "CanEditCourse",

  CAN_VIEW_QUIZ = "CanViewQuiz",
  CAN_EDIT_QUIZ = "CanEditQuiz",

  CAN_VIEW_COUPON = "CanViewCoupon",
  CAN_EDIT_COUPON = "CanEditCoupon",

  CAN_VIEW_RESOURCE = "CanViewResource",
  CAN_EDIT_RESOURCE = "CanEditResource",
  
  CAN_VIEW_VIDEO = "CanViewVideo",
  CAN_EDIT_VIDEO = "CanEditVideo",
}

export class UserService {
  private static instance: UserService;
  private _user: AuthUser | null = null; // Contiene i dati dell'utente
  private _permissions: IDPPermission[] = []; // Contiene i permessi dell'utente
  private _isAdmin: boolean = false; // Se l'utente è admin o meno

  set user(user: AuthUser | null) {
    this._user = user;
  }

  get user(): AuthUser | null {
    return this._user;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  set isAdmin(isAdmin: boolean) {
    this._isAdmin = isAdmin;
  }

  set permissions(permissions: IDPPermission[]) {
    this._permissions = permissions
  }

  get permissions(): IDPPermission[] {
    return this._permissions;
  }

  private constructor() {
    // Costruttore privato
  }
  

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  // Ritorna true se l'utente è settato, false altrimenti
  // public async getUserData() {
  //   if (this.user && AuthService.getInstance().accessToken) {
  //     return true;
  //   }
  
  //   if (AuthService.getInstance().accessToken && !this.user) {
  //     // Richiamo l'endpoint per ottenere i dati dell'utente
  //     const response = await axiosAuthInstance.get(
  //       `${ApiConfig.ROOT_URL ?? ""}/auth/user`
  //     );
  //     this.user = response.data;

  //     return true;
  //   }
  //   return false;
  // }

  public async getUserData() {
    if (!AuthService.getInstance().accessToken) {
      return false;
    }
    const response = await axiosAuthInstance.get(
      `${ApiConfig.ROOT_URL ?? ""}/auth/user`
    );

    this.user = response.data;

    return true;
  }


  // public async refreshUserData() {
  //   if (AuthService.getInstance().accessToken) {
  //     // Richiamo l'endpoint per ottenere i dati dell'utente
  //     const response = await axiosAuthInstance.get(
  //       `${ApiConfig.ROOT_URL ?? ""}/auth/user`
  //     );
  //     this.user = response.data;

  //     return true;
  //   }

  //   return false;
  // }

  // Creo una funzione per controllare se l'utente ha un determinato permesso
  public hasPermission(permission: IDPPermission): boolean {
    return this.permissions.includes(permission);
  }
}
